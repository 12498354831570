// @ts-nocheck
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'; // eslint-disable-line
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import templatesConfigSlice from './slices/templatesConfig';
import allGamesSlice from './slices/allGames';
import allCountriesSlice from './slices/allCountries';
import jackpotsMetaSlice from './slices/jackpotsMeta';
import dataSourcesSlice from './slices/dataSources';
import dataElementsSlice from './slices/dataElements';
import wheelsDataSourceSlice from './slices/wheelsDataSource';
import rankSystemsSlice from './slices/rankSystems';
import shopItemSlice from './slices/shopItem';
//import momentumSlice from './slices/momentum';
import currenciesReducer from './slices/currencies';
import popupBonusesReducer from './slices/popupBonuses';
import maintenanceReducer from './slices/maintenance';
import socialReducer from './slices/social';
import leagueStatsReducer from './slices/leagueStats';
import airdropReducer from './slices/airdrop';
import challengesReducer from './slices/challenges';
import pragmaticLiveReducer from './slices/pragmaticLive';

import applicationReducer from '../modules/casino/store/reducers/application';
import menuReducer from '../modules/casino/store/reducers/menu';
import authenticationReducer from '../modules/casino/store/reducers/authentication';
import loginReducer from '../modules/casino/store/reducers/login';
import walletReducer from '../modules/casino/store/reducers/wallet';
import multiCurrencyWalletReducer from './slices/multiCurrencyWallet';
import registerReducer from '../modules/casino/store/reducers/register';
import resetPasswordReducer from '../modules/casino/store/reducers/resetPassword';
import profileReducer from '../modules/casino/store/reducers/profile';
import transactionsReducer from '../modules/casino/store/reducers/transactions';
import paymentCheckoutReducer from '../modules/casino/store/reducers/paymentCheckout';
import withdrawalCheckoutReducer from '../modules/casino/store/reducers/withdrawalCheckout';
import withdrawalsReducer from '../modules/casino/store/reducers/withdrawals';
import withdrawWizardReducer from '../modules/casino/store/reducers/withdraw_wizard';
import documentsReducer from '../modules/casino/store/reducers/documents';
import gamesReducer from '../modules/casino/store/reducers/games';
import customerSupportReducer from '../modules/casino/store/reducers/customer_support';
import jackpotReducer from '../modules/casino/store/reducers/jackpot';
import happyHourReducer from '../modules/casino/store/reducers/happy_hour';
import configReducer from '../modules/casino/store/reducers/config';
import layoutReducer from '../modules/casino/store/reducers/layout';
import freeBetsReducer from '../modules/casino/store/reducers/free_bets';
import voucherCodeReducer from '../modules/casino/store/reducers/voucherCode';
import requestManagerReducer from '../modules/casino/store/reducers/requestManager';
import freeSpinsReducer from '../modules/casino/store/reducers/free_spins';
import alertsReducer from '../modules/casino/store/reducers/alerts';
import druidReducer from '../modules/casino/store/reducers/druid';
import jackpotListReducer from '../modules/casino/store/reducers/jackpotList';
import marketingOfferReducer from '../modules/casino/store/reducers/marketing_offer';
import depositReducer from '../modules/casino/store/reducers/deposit';
import { betsRootReducer } from '../modules/bets/store/reducers';
import { virtualsRootReducer } from '../modules/virtuals/store/reducers';
import { lottoRootReducer } from '../modules/lotto/store/reducers';
import liveCasinoReducer from '../modules/live-casino/store/reducers';
import { wheelRootReducer } from '../modules/wheel/store/reducers';
import { tournamentsMissionsRootReducer } from '../modules/tournaments-missions/store/reducers';
import { depositTicketRootReducer } from '../modules/deposit-ticket/store/reducers';
import { winnerFunRootReducer } from '../modules/winner-fun/store/reducers';

import ClientAPI from '../modules/casino/ClientAPI/ClientAPI';
import { rootSaga } from '../modules/casino/store/sagas/index';

import { setStore as betsSetStore } from '../modules/bets/store';

import { setStore as virtualsSetStore } from '../modules/virtuals/store';

import { setStore as lottoSetStore } from '../modules/lotto/store';
import VivaAPI from '../modules/casino/ClientAPI/VivaAPI.js';

import { setStore as liveCasinoSetStore } from '../modules/live-casino/store';

import { setStore as winnerFunSetStore } from '../modules/winner-fun/store';

import { setStore as lobbySetStore } from '../modules/lobby/store';
import { lobbyRootReducer } from '../modules/lobby/store/reducers';

import { setStore as promoSetStore } from '../modules/promo/store';
import { promoRootReducer } from '../modules/promo/store/reducers';

import { setStore as promoCalendarSetStore } from '../modules/promo-calendar/store';
import { promoCalendarRootReducer } from '../modules/promo-calendar/store/reducers';

import { setStore as wheelSetStore } from '../modules/wheel/store';

import { setStore as tournamentsGroupsSetStore } from '../modules/tournaments-missions/store';

import { setStore as depositTicketSetStore } from '../modules/deposit-ticket/store';

import { setStore as momentumSetStore } from '../modules/momentum/store';
import { momentumRootReducer } from '../modules/momentum/store/reducers';

// @ts-ignore
const asyncDispatchMiddleware = (store) => (next) => (action) => {
  let syncActivityFinished = false; // @ts-ignore
  let actionQueue = [];

  function flushQueue() {
    // @ts-ignore
    actionQueue.forEach((a) => store.dispatch(a)); // flush queue
    actionQueue = [];
  }
  // @ts-ignore
  function asyncDispatch(asyncAction) {
    // @ts-ignore
    actionQueue = actionQueue.concat([asyncAction]);

    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

  const res = next(actionWithAsyncDispatch);
  syncActivityFinished = true;
  flushQueue();

  return res;
};

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    application: applicationReducer,
    currencies: currenciesReducer,
    menu: menuReducer,
    authentication: authenticationReducer,
    login: loginReducer,
    wallet: walletReducer,
    multiCurrencyWallet: multiCurrencyWalletReducer,
    register: registerReducer,
    resetPassword: resetPasswordReducer,
    profile: profileReducer,
    transactions: transactionsReducer,
    withdrawalCheckout: withdrawalCheckoutReducer,
    paymentCheckout: paymentCheckoutReducer,
    withdrawals: withdrawalsReducer,
    documents: documentsReducer,
    games: gamesReducer,
    withdrawWizard: withdrawWizardReducer,
    customerSupport: customerSupportReducer,
    jackpot: jackpotReducer,
    bets: betsRootReducer,
    virtuals: virtualsRootReducer,
    lotto: lottoRootReducer,
    happyHour: happyHourReducer,
    config: configReducer,
    liveCasino: liveCasinoReducer,
    layout: layoutReducer,
    freeBets: freeBetsReducer,
    voucherCode: voucherCodeReducer,
    requestManager: requestManagerReducer,
    freeSpins: freeSpinsReducer,
    alerts: alertsReducer,
    druid: druidReducer,
    jackpotList: jackpotListReducer,
    wheel: wheelRootReducer,
    marketingOffer: marketingOfferReducer,
    tournamentsMissions: tournamentsMissionsRootReducer,
    allGames: allGamesSlice,
    maintenance: maintenanceReducer,
    allCountries: allCountriesSlice,
    depositTicket: depositTicketRootReducer,
    templatesConfig: templatesConfigSlice,
    jackpotsMeta: jackpotsMetaSlice,
    dataSources: dataSourcesSlice,
    dataElements: dataElementsSlice,
    wheelsDataSource: wheelsDataSourceSlice,
    deposit: depositReducer,
    rankSystems: rankSystemsSlice,
    shopItem: shopItemSlice,
    winnerFun: winnerFunRootReducer,
    popupBonuses: popupBonusesReducer,
    promoCalendar: promoCalendarRootReducer,
    social: socialReducer,
    momentum: momentumRootReducer,
    leagueStats: leagueStatsReducer,
    airdrop: airdropReducer,
    challenges: challengesReducer,
    pragmaticLive: pragmaticLiveReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // we need this because bets config has function definition for bonnus rules in action data
    }).concat(asyncDispatchMiddleware, sagaMiddleware),
});

ClientAPI.setStore(store);
VivaAPI.setStore(store);

if (window.config && (window.config.betsEnabled === '1' || window.config.nsoftEnabled === '1')) {
  betsSetStore(store);
}

if (window.config && window.config.virtualsEnabled === '1') {
  virtualsSetStore(store);
}

if (window.config && window.config.lottoEnabled === '1') {
  lottoSetStore(store);
}

if (window.config && window.config.winnerFunEnabled === '1') {
  winnerFunSetStore(store);
}
if (window.config && window.config.liveCasinoEnabled === '1') {
  liveCasinoSetStore(store);
}

if (window.config && window.config.lobbyEnabled === '1') {
  lobbySetStore(store);
}

if (window.config && window.config.wheelEnabled === '1') {
  wheelSetStore(store);
}

if (window.config && window.config.promoEnabled === '1') {
  promoSetStore(store);
}

if (window.config && window.config.tournamentsMissionsEnabled === '1') {
  tournamentsGroupsSetStore(store);
}

if (window.config && window.config.depositTicketEnabled === '1') {
  depositTicketSetStore(store);
}

if (window.config && window.config.promoCalendarEnabled === '1') {
  promoCalendarSetStore(store);
}

if (window.config && window.config.momentumEnabled === '1') {
  momentumSetStore(store);
}

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
