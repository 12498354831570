import React from 'react';
import useDeepCompareMemo from '../../utils/useDeepCompareMemo';

export const getStateValue: any = (state: any, stateKey: string) => {
  //console.log('getStateValue', { state, stateKey });

  if (stateKey && stateKey.indexOf('state.') === 0) {
    const statePath = stateKey.replace('state.', '');
    const statePaths = statePath.split('.');

    const skip = [
      'state.activeBonuses',
      'state.activeBonuses.loader',
      'state.pendingBonuses',
      'state.pendingBonuses.loader',
      'state.transactionsHistory',
      'state.bonusesHistory',
      'state.gameSessionsHistory',
      'state.freeBetsHistory',
      'state.freeSpinsHistory',
      'state.bets.live.matches.count',
    ];
    let data = null;

    if (skip.indexOf(stateKey) === -1) {
      data = state;
      for (let i = 0; i < statePaths.length; i++) {
        const currentPath: string = statePaths[i];
        // @ts-ignore
        if (data && data[currentPath] != null) {
          // @ts-ignore
          data = data[currentPath];
        } else {
          return null;
        }
      }
    } else {
      if (stateKey === 'state.activeBonuses') {
        return getActiveBonuses(state);
      } else if (stateKey === 'state.activeBonuses.loader') {
        return getActiveBonusesLoader(state);
      } else if (stateKey === 'state.pendingBonuses') {
        return getPendingBonuses(state);
      } else if (stateKey === 'state.pendingBonuses.loader') {
        return getPendingBonusesLoader(state);
      } else if (stateKey === 'state.transactionsHistory') {
        return getTransactionsHistory(state);
      } else if (stateKey === 'state.bonusesHistory') {
        return getBonusesHistory(state);
      } else if (stateKey === 'state.gameSessionsHistory') {
        return getGameSessionsHistory(state);
      } else if (stateKey === 'state.freeBetsHistory') {
        return getFreeBetsHistory(state);
      } else if (stateKey === 'state.freeSpinsHistory') {
        return getFreeSpinsHistory(state);
      } else if (stateKey === 'state.bets.live.matches.count') {
        return { count: Object.keys(state.bets.live.matches).length };
      }
    }

    if (data) {
      if (stateKey === 'state.application.availableBonuses') {
        return Object.values(data);
      } else if (stateKey === 'state.application.eligibleBonuses') {
        return Object.values(data);
      }
      return data;
    }
  }
  return null;
};

const getActiveBonuses = (state: any) => {
  //const bonuses = useDeepCompareMemo(() => {
  const bonuses: any[] = [];
  const freebets: any[] = [];
  const freespins: any[] = [];

  if (
    state &&
    state.wallet &&
    state.wallet.bonuses &&
    Array.isArray(state.wallet.bonuses) &&
    state.wallet.bonuses.length
  ) {
    state.wallet.bonuses.forEach((bonus: any) => {
      bonuses.push({
        type: 'bonus',
        bonus: {
          element_type: 'inner',
          data: {
            ...bonus,
            delete_action: 'activeBonuses.deleteBonus',
          },
        },
      });
    });
  }
  if (
    state &&
    state.freeBets &&
    state.freeBets.freeBets &&
    Array.isArray(state.freeBets.freeBets) &&
    state.freeBets.freeBets.length
  ) {
    state.freeBets.freeBets.forEach((freebet: any) => {
      freebets.push({
        type: 'freebet',
        freebet: {
          element_type: 'inner',
          data: {
            ...freebet,
            delete_action: 'activeBonuses.deleteFreebet',
          },
        },
      });
    });
  }
  if (
    state &&
    state.freeSpins &&
    state.freeSpins.freeSpins &&
    Array.isArray(state.freeSpins.freeSpins) &&
    state.freeSpins.freeSpins.length
  ) {
    state.freeSpins.freeSpins.forEach((freespin: any) => {
      freespins.push({
        type: 'freespin',
        freespin: {
          element_type: 'inner',
          data: {
            ...freespin,
            delete_action: 'activeBonuses.playFreespin',
          },
        },
      });
    });
  }

  return [...bonuses, ...freebets, ...freespins];
  //}, [state.wallet.bonuses, state.freeBets.freeBets, state.freeSpins.freeSpins]);

  //return bonuses;
};

const getActiveBonusesLoader = (state: any) => {
  let loading = false;
  let loaded = false;
  let total = 0;

  if (state?.wallet?.loading) loading = true;
  if (state?.freeBets?.loading) loading = true;
  if (state?.freeSpins?.loading) loading = true;

  if (!loading) {
    loaded = true;
  }

  if (loaded) {
    const bonuses = getActiveBonuses(state);
    total = bonuses.length;
  }

  return {
    loading: loading,
    loaded: loaded,
    total: total,
  };
};

const getPendingBonuses = (state: any) => {
  const pendingBonuses: any[] = [];
  const tournamentPrizes: any[] = [];
  if (
    state &&
    state.tournamentsMissions &&
    state.tournamentsMissions.history &&
    state.tournamentsMissions.history.prizes &&
    Array.isArray(state.tournamentsMissions.history.prizes) &&
    state.tournamentsMissions.history.prizes.length
  ) {
    state.tournamentsMissions.history.prizes.forEach((prize: any) => {
      tournamentPrizes.push({
        type: 'prize',
        prize: {
          element_type: 'inner',
          data: {
            ...prize,
            activate_action: 'pendingBonuses.activatePrize',
          },
        },
      });
    });
  }

  const availableBonuses: any[] = [];
  if (state && state.application && state.application.availableBonuses) {
    const avB = Object.values(state.application.availableBonuses);
    avB.forEach((bonus: any) => {
      availableBonuses.push({
        type: 'bonus',
        bonus: {
          element_type: 'inner',
          data: {
            ...bonus,
            activate_action: 'pendingBonuses.activateBonus',
          },
        },
      });
    });
  }

  if (Array.isArray(tournamentPrizes)) {
    pendingBonuses.push(...tournamentPrizes);
  }

  if (Array.isArray(availableBonuses)) {
    pendingBonuses.push(...availableBonuses);
  }

  return pendingBonuses;
};

const getPendingBonusesLoader = (state: any) => {
  let loading = false;
  let loaded = false;
  let total = 0;

  if (state?.tournamentsMissions?.history?.loadingPrizes) loading = true;
  if (!state?.application?.availableBonuses) loading = true;
  if (!loading) loaded = true;
  if (loaded) {
    const pBonuses = getPendingBonuses(state);
    total = pBonuses.length;
  }

  return {
    loading: loading,
    loaded: loaded,
    total: total,
  };
};

const getTransactionsHistory = (state: any) => {
  //const transactions = useDeepCompareMemo(() => {
  const transactionsHistory: any[] = [];

  if (
    state &&
    state.transactions &&
    state.transactions.depositsWithdrawals &&
    Array.isArray(state.transactions.depositsWithdrawals) &&
    state.transactions.depositsWithdrawals.length
  ) {
    const transactionTypes = {
      0: 'unknown',
      // Deposit
      1: 'deposit',
      24: 'deposit',
      30: 'deposit',
      53: 'deposit',
      85: 'deposit',
      114: 'deposit',
      130: 'deposit',
      140: 'deposit',
      231: 'deposit',
      // Withdrawal
      2: 'withdraw',
      10: 'withdraw',
      11: 'withdraw',
      12: 'withdraw',
      25: 'withdraw',
      26: 'withdraw',
      27: 'withdraw',
      28: 'withdraw',
      54: 'withdraw',
      55: 'withdraw',
      56: 'withdraw',
      120: 'withdraw',
      121: 'withdraw',
      122: 'withdraw',
      141: 'withdraw',
      142: 'withdraw',
      143: 'withdraw',
      232: 'withdraw',
      233: 'withdraw',
      234: 'withdraw',
    };

    const transactionsState = [...state.transactions.depositsWithdrawals];

    transactionsState?.sort((a: any, b: any) => {
      if (a.status_change_date && b.status_change_date) {
        if (a?.status_change_date > b?.status_change_date) {
          return -1;
        }
        if (a?.status_change_date < b?.status_change_date) {
          return 1;
        }
      } else if (!a.status_change_date && b.status_change_date) {
        if (a?.insert_date > b?.status_change_date) {
          return -1;
        }
        if (a?.insert_date < b?.status_change_date) {
          return 1;
        }
      } else if (a.status_change_date && !b.status_change_date) {
        if (a?.status_change_date > b?.insert_date) {
          return -1;
        }
        if (a?.status_change_date < b?.insert_date) {
          return 1;
        }
      } else {
        if (a?.insert_date > b?.insert_date) {
          return -1;
        }
        if (a?.insert_date < b?.insert_date) {
          return 1;
        }
      }
      return 0;
    });

    transactionsState.forEach((transaction: { type: number }) => {
      // @ts-ignore
      const type = transactionTypes[transaction.type] != null ? transactionTypes[transaction.type] : 'unknown';

      const entry = {
        type: type,
        [type]: {
          element_type: 'inner',
          data: transaction,
        },
      };

      transactionsHistory.push(entry);
    });
  }

  return transactionsHistory;
  //}, [state.transactions?.depositsWithdrawals]);

  //return transactions;
};

const getBonusesHistory = (state: any) => {
  //const bonuses = useDeepCompareMemo(() => {
  const bonusesHistory: any[] = [];

  if (
    state &&
    state.transactions &&
    state.transactions.bonuses &&
    Array.isArray(state.transactions.bonuses) &&
    state.transactions.bonuses.length
  ) {
    state.transactions.bonuses.forEach((bonus: any) => {
      const entry = {
        bonus: {
          element_type: 'inner',
          data: bonus,
        },
      };

      bonusesHistory.push(entry);
    });
  }

  bonusesHistory.sort((a, b) => {
    return b.bonus.data.startDate - a.bonus.data.startDate;
  });

  return bonusesHistory;
  //}, [state.transactions?.bonuses]);

  //return bonuses;
};

const getGameSessionsHistory = (state: any) => {
  //const spins = useDeepCompareMemo(() => {
  const spinsHistory: any[] = [];

  if (
    state &&
    state.transactions &&
    state.transactions.spins &&
    Array.isArray(state.transactions.spins) &&
    state.transactions.spins.length
  ) {
    state.transactions.spins.forEach((spin: any) => {
      const entry = {
        game: {
          element_type: 'inner',
          data: spin,
        },
      };

      spinsHistory.push(entry);
    });
  }

  return spinsHistory;
  //}, [state.transactions?.spins]);

  //return spins;
};

const getFreeBetsHistory = (state: any) => {
  //const freeBets = useDeepCompareMemo(() => {
  const freeBetsHistory: any[] = [];

  if (
    state &&
    state.freeBets &&
    state.freeBets.freeBetsHistory &&
    Array.isArray(state.freeBets.freeBetsHistory) &&
    state.freeBets.freeBetsHistory.length
  ) {
    state.freeBets.freeBetsHistory.forEach((freeBet: any) => {
      const entry = {
        freeBet: {
          element_type: 'inner',
          data: freeBet,
        },
      };

      freeBetsHistory.push(entry);
    });
  }

  freeBetsHistory.sort((a, b) => {
    return b.freeBet.data.created - a.freeBet.data.created;
  });

  return freeBetsHistory;
  // }, [state.freeBets?.freeBetsHistory]);

  //return freeBets;
};

const getFreeSpinsHistory = (state: any) => {
  //const freeSpins = useDeepCompareMemo(() => {
  const freeSpinsHistory: any[] = [];

  if (
    state &&
    state.freeSpins &&
    state.freeSpins.freeSpinsHistory &&
    Array.isArray(state.freeSpins.freeSpinsHistory) &&
    state.freeSpins.freeSpinsHistory.length
  ) {
    state.freeSpins.freeSpinsHistory.forEach((freeSpin: any) => {
      const entry = {
        freeSpin: {
          element_type: 'inner',
          data: freeSpin,
        },
      };

      freeSpinsHistory.push(entry);
    });
  }

  freeSpinsHistory.sort((a, b) => {
    return b.freeSpin.data.insert_date - a.freeSpin.data.insert_date;
  });

  return freeSpinsHistory;
  //}, [state.freeSpins?.freeSpinsHistory]);

  //return freeSpins;
};
