import moment from 'moment';

export const encodeTagsClient = (str: string) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || '';
};

export const formatDuration = (timestamp: string, fromNow: boolean = false) => {
  const createdAt = moment(timestamp);

  if (fromNow) return createdAt.fromNow();

  const now = moment();
  const isToday = now.isSame(createdAt, 'day');
  //   const isYesterday = now.clone().subtract(1, 'days').isSame(createdAt, 'day');

  if (isToday) {
    return createdAt.format('HH:mm');
    //   } else if (isYesterday) {
    // return `Yesterday, ${createdAt.format('HH:mm')}`;
  } else {
    return createdAt.format('DD.MM HH:mm');
  }
};
