import React, { useContext } from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash-es';
import { useMatches } from 'react-router-dom';

import { DataElementContext } from '../common/DataElementContext';
import resolveDataElement from '../../utils/data-element-api-cache';
import genericDataSource from '../../utils/generic-data-source-api-cache';

import { processComponentProps } from '@/page-components/utils/processComponentProps';

const defaultProps = {
  className: '',
  styleText: `
  width: 100%;
  height: auto;
  `,
  properties: {
    dsType: '',
    deId: '',
  },
};

interface DataElementProps {
  className: string;
  $styleText: string;
  style?: any;
  properties?: {
    dsType: string;
    deId: string;
  };
  children?: JSX.Element;
}

const DataElementDiv = styled.div<DataElementProps>((props) => props.$styleText);

export const DataElement = (componentProps: any) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const [__id] = React.useState(uniqueId('unique-'));
  const deId = props.properties?.deId ?? '';
  const dsId = props.properties?.dsId ?? '';
  const useDataSource = props.properties.useDataSource ?? false;
  const filterKey = props.properties.key ?? null;
  let filterValue = props.properties.value ?? null;
  const valueFromParam = props.properties.valueFromParam ?? false;
  const matches = useMatches();

  if (useDataSource) {
    if (valueFromParam && props?.properties?.pathParamKey) {
      if (matches && matches.length) {
        // there is no gameId set as props; maybe were in a route that includes a :gameId param
        const match = matches[0];
        let pathParamKey = props.properties.pathParamKey;
        if (pathParamKey != null && pathParamKey[0] === ':') {
          pathParamKey = pathParamKey.slice(1);
        }
        if (match.params && match.params[pathParamKey] != null) {
          filterValue = match.params[pathParamKey];
        }
      }
    }
  }

  let data: any = {};
  let loading = true;
  let loaded = false;

  const dataElement = resolveDataElement({ deId });
  if (deId && dataElement?.data?.data) {
    data = dataElement?.data?.data;
    loading = dataElement.loading;
    loaded = dataElement.loaded;
  }

  const dataSource: any = genericDataSource({ dsId, filterKey, filterValue });
  if (dsId && dataSource) {
    if (dataSource?.data?.data && dataSource?.data?.data?.length === 1) {
      data = dataSource.data.data[0];
    }
    loading = dataSource.loading;
    loaded = dataSource.loaded;
  }

  const contextValue = React.useMemo(() => {
    return {
      ...data,
      __loading: loading,
      __loaded: loaded,
      __id,
    };
  }, [componentProps, data]);

  if (!isVisible) return null;

  //console.log('DataElement[contextValue]', contextValue, data);

  return (
    <DataElementDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </DataElementDiv>
  );
};
