import * as actionTypes from './../actions/actionTypes';
import i18n from '../../../../utils/i18n';

const initialState = {
  topMenuHeight: null,
  pageType: null,
  pageCode: null,
  resizeCounter: 0,
  pageClassName: '',
  showGlobalWidgets: false,
  language: i18n.language ?? false,
  promotions: [],
  eligibleBonuses: null,
  availableBonuses: null,
  retailBonuses: null,
  preEuroTickets: 0,
  lastProductId: false,
  bonusClaimError: null,
  loadingClaimBonus: false,
  eventLog: [],
  routerHistory: {
    pages: -1,
    currentPath: '',
    pagesHistory: [],
  },
  inGameHeaderSelection: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.application.SET_IN_GAME_HEADER_SELECTION:
      return {
        ...state,
        inGameHeaderSelection: action.selection,
      };
    case actionTypes.application.SET_ROUTER_HISTORY:
      return {
        ...state,
        routerHistory: action.data,
      };
    case actionTypes.application.SET_MENU_HEIGHT:
      return {
        ...state,
        topMenuHeight: action.menuHeight,
      };
    case actionTypes.application.SET_PAGE_CODE:
      return {
        ...state,
        pageCode: action.code,
      };
    case actionTypes.application.SET_PAGE_TYPE:
      return {
        ...state,
        pageType: action.pageType,
      };
    case actionTypes.application.RESIZE:
      let newCounter = state.resizeCounter + 1;

      return {
        ...state,
        resizeCounter: newCounter,
      };
    case actionTypes.application.SET_PAGE_CLASSNAME:
      return {
        ...state,
        pageClassName: action.className,
      };
    case actionTypes.application.SHOW_GLOBAL_WIDGET:
      return {
        ...state,
        showGlobalWidgets: true,
      };
    case actionTypes.application.HIDE_GLOBAL_WIDGET:
      return {
        ...state,
        showGlobalWidgets: false,
      };
    case actionTypes.application.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case actionTypes.application.SET_PROMOTIONS:
      return {
        ...state,
        promotions: action.promotions ? action.promotions : [],
      };
    case actionTypes.application.SET_ELIGIBLE_BONUSES:
      return {
        ...state,
        eligibleBonuses: action.bonuses ? action.bonuses : [],
      };

    case actionTypes.application.CLAIM_BONUS:
      return {
        ...state,
        loadingClaimBonus: true,
      };
    case actionTypes.application.BONUS_CLAIMED:
      return {
        ...state,
        loadingClaimBonus: false,
        bonusClaimError: action.result.detail
          ? action.result.detail
          : action.result.ResponseCode === 6
            ? {
              canRetry: false,
            }
            : !!action.result.ResponseCode,
      };

    case actionTypes.application.RESET_BONUS_CLAIM_STATUS:
      return {
        ...state,
        loadingClaimBonus: false,
        bonusClaimError: null,
      };
    case actionTypes.application.SET_AVAILABLE_BONUSES:
      if (typeof action.bonuses.ResponseCode !== 'undefined') {
        if (Array.isArray(state.availableBonuses) && state.availableBonuses.length === 0) {
          return state;
        } else {
          return {
            ...state,
            availableBonuses: [],
          };
        }
      }

      return {
        ...state,
        availableBonuses: action.bonuses ? action.bonuses : [],
      };
    case actionTypes.bonuses.SET:
      if (typeof action.bonuses.ResponseCode !== 'undefined') {
        if (Array.isArray(state.retailBonuses) && state.retailBonuses === 0) {
          return state;
        } else {
          return {
            ...state,
            retailBonuses: [],
          };
        }
      }

      return {
        ...state,
        retailBonuses: action.bonuses ? action.bonuses : [],
      };
    case actionTypes.bonuses.SET_AWARD_BONUS:
      if (typeof action.data.ResponseCode !== 'undefined') {
        return state;
      }
      if (state.eligibleBonuses[action.bonusId]) {
        return {
          ...state,
          bonusAward: {
            bonusId: action.bonusId,
            awardAmount: action.data.awardAmount,
            tokenCount: action.data.tokenCount,
          },
        };
      }
      return state;
    case actionTypes.application.SET_PREEURO:
      return {
        ...state,
        preEuroTickets: action.tickets,
      };
    case actionTypes.application.SET_LAST_PRODUCT_ID:
      if (!action.productId) {
        return state;
      }
      let logevent = [...state.eventLog];
      logevent.push({ t: new Date().getTime(), event: 'Product ' + action.productId });

      return {
        ...state,
        lastProductId: action.productId,
        eventLog: logevent,
      };
    case actionTypes.application.PUSH_EVENT_LOG:
      let log = [...state.eventLog];
      log.push({ t: new Date().getTime(), event: action.event });
      return {
        ...state,
        eventLog: log,
      };
    default:
      return state;
  }
};

export default reducer;
