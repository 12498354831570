import './dev.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Bootstrap Bundle JS
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.scss';

import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n'; // the initialized i18next instance

import './utils/logger'; // needed for the logger to be initialez
import store from './store';
import { fetchConfig } from './store/slices/templatesConfig';

import App from './app';

import PragmaticLive from './store/sagas/pragmaticLiveWS';

// add the root, page-layout and page style rules containers (as style tags)
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
);

// get the pages configuration; TODO: fix the hardcoded tenantID and siteId
if (import.meta.env.DEV) {
  const tenantId = window.config.clientId;
  const siteId = window.config.siteId;
  document.documentElement.classList.toggle(`-s-${siteId}`);
  store.dispatch(fetchConfig({ tenantId: tenantId, siteId: siteId }));
} else {
  document.documentElement.classList.toggle(`-s-${window.config.siteId}`);
  store.dispatch(fetchConfig({ tenantId: window.config.clientId, siteId: window.config.siteId }));
}
//store.dispatch(fetchAllGamesConfig({ tenantId: window.config.clientId, siteId: window.config.siteId }));

// Disabled international SMS feature, set RO to be the default timezone.
// store.dispatch(fetchAllCountriesConfig({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
//store.dispatch(fetchAllCountriesConfig({ timezone: 'Europe/Bucharest' }));
//store.dispatch(appInitialize());
//store.dispatch(startAppAuth());

// for inspecting state in console dev
// @ts-ignore
window._store = store;

const showErrorOverlay = (err: any) => {
  console.error('error', err);
};

import.meta.hot?.on('vite:error', showErrorOverlay);

PragmaticLive.connect();
