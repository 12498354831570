interface PaymentProviderInterface {
  [key: string]: number;
}

interface PaymentProviderNameByIdInterface {
  [key: number]: string;
}

export const PaymentProvider: PaymentProviderInterface = {
  cash: 1,
  safecharge: 2,
  viva: 3,
  okto: 4,
  skrill: 5,
  abon: 6,
  bridger: 7,
};

export const PaymentProviderWithdraw: PaymentProviderInterface = {
  // needs matching with core
  viva: 3,
  iban: 4,
  skrill: 5,
  bridger: 7,
};

export const paymentProviderNameById: PaymentProviderNameByIdInterface = {
  [PaymentProvider.cash]: 'Cash',
  [PaymentProvider.safecharge]: 'Safecharge',
  [PaymentProvider.viva]: 'Viva',
  [PaymentProvider.bridger]: 'Bridger',
  [PaymentProvider.okto]: 'Okto',
  [PaymentProvider.abon]: 'Abon',
  [PaymentProvider.skrill]: 'Skrill',
};

export const paymentProviderNameByWithdrawId: PaymentProviderNameByIdInterface = {
  [PaymentProviderWithdraw.viva]: 'Viva',
  [PaymentProviderWithdraw.skrill]: 'Skrill',
  [PaymentProviderWithdraw.iban]: 'IBAN',
  [PaymentProviderWithdraw.bridger]: 'Bridger',
};

export const DEFAULT_AMOUNT: number = 20;

// this have styling hard coded in websources/src/components/modules/deposit/index.scss
export const HTML_3DS_IFRAME_ID: string = 'validation3ds';
export const HTML_3DS_IFRAME_WRAPPER: string = 'deposit-module'; /// fallback to document.body
