import React, { useContext, useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';

import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { useMediaQuery } from '@/components/utils/useQueryMedia';

export const LottiePlayer = (componentProps) => {
  let props = componentProps;

  const [animationData, setAnimationData] = useState({
    data: null,
    ref: 0,
  });
  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  const isTablet = useMediaQuery(`(min-width: ${window.config.breakpointTablet})`);
  const isDesktop = useMediaQuery(`(min-width: ${window.config.breakpointDesktop})`);
  const breakpoints = React.useMemo(() => ({ isTablet, isDesktop }), [isTablet, isDesktop]);

  [props, isVisible] = processComponentProps(props, dataElementContext, breakpoints);

  const fecthJson = React.useCallback(async (src) => {
    try {
      const data = await fetch(src);
      if (data.headers.get('Content-Type') === 'application/json') {
        const lottieJosn = await data.json();
        setAnimationData(v => ({ data: lottieJosn, ref: v.ref + 1 }));
      }
    } catch (err) { }
  }, []);

  useEffect(() => {
    if (props.properties.src) {
      fecthJson(props.properties.src);
    }
  }, [props.properties.src]);

  if (!isVisible) return null;
  if (!animationData.data) return null;

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  return (
    <Lottie
      key={`id_${animationData.ref}`}
      loop={props.properties.loop ? true : false}
      play={props.properties.play ? true : false}
      animationData={animationData.data}
      className={props.className ?? ''}
      style={props.style}
      data-node-id={props.nodeId}
      onClick={props.properties.onClick}
      {...extraDataProps}
    />
  );
};

export default React.memo(LottiePlayer, equalWithoutChildren);
