import { debug } from './';
import moment from 'moment';

const liveTeamSort = (t1, t2) => parseInt(t1.Side, 10) - parseInt(t2.Side, 10);

function normalizeDigitainLiveTicket(t) {
  debug('normalize digitain', t);

  const dt = JSON.parse(t.ticket);
  let dr = null;
  if (t.result) {
    dr = JSON.parse(t.result);
  }
  const bst = null;

  debug('dt', dt, dr);

  const ticket = {
    provider: 'digitain',
    id: t.serial,
    idHash: t.serial,
    product: 'Live',
    format: 'live',
    type: dt.BetType === 2 ? 'SYSTEM' : 'SIMPLU',
    status: t.status.toUpperCase(),
    winning: t.possible_win,
    payout: t.winnings ? t.winnings : 0,
    cashouted: t.ticket_status === 'cashout',
    minWinning: 0,
    totalOdd: t.possible_win / t.amount,
    stake: t.amount,
    createdAt: moment.utc(t.created_at).valueOf(),
    systemType: [dt.SystemIndex],
    hasBonus: t.hasBonus ? true : false,
    minBonus: t.minBonus ? t.minBonus : 0,
    maxBonus: t.maxBonus ? t.maxBonus : 0,
    minBonusPercentage: t.minBonusPercentage ? t.minBonusPercentage : 0,
    maxBonusPercentage: t.maxBonusPercentage ? t.maxBonusPercentage : 0,
    bonusWonAmount: t.bonusWonAmount ? t.bonusWonAmount : 0,
    paidOutBonus: t.paidOutBonus ? t.paidOutBonus : 0,
    requestUuid: t.requestUuid,
    //wf_event_balance_multiplier: t.wf_event_balance_multiplier ? t.wf_event_balance_multiplier : 0,
    clientVal: dt.AdditionalInfo ? dt.AdditionalInfo : {},
    flags: t.flags ? t.flags : 0,
  };

  if (dt.AdditionalInfo) {
    try {
      ticket.clientVal = JSON.parse(dt.AdditionalInfo);
    } catch (err) { }
  }

  if (dt.clientVal) {
    try {
      ticket.clientVal = dt.clientVal;
    } catch (err) { }
  }

  if (ticket.status === 'PAIDOUT') {
    ticket.status = 'WON';
    ticket.origStatus = 'PAID OUT';
  }

  if (ticket.status === 'PENDING') {
    ticket.status = 'OPEN';
    ticket.origStatus = 'PENDING';
  }

  const ts = [];
  let won = 0;
  let lost = 0;
  let bankers = 0;

  const stStatus = {
    1: 'n/a',
    2: 'Won',
    3: 'Lost',
    4: 'Return',
    5: 'Half-return',
    6: 'Half-win',
    7: 'Rejected',
    8: 'Initial Return',
    9: 'Initial Half Return',
    10: 'Initial Half Won',
  };

  ticket.bets = dt.StakesList.map(b => {
    let teams = [...b.Event.Teams];

    if (b?.Event?.Teams?.length > 2) {
      teams = [
        {
          Side: 1,
          Name: [],
        },
        {
          Side: 2,
          Name: [],
        },
      ];

      b.Event.Teams.forEach((t, i) => {
        if (t.Side === 1) {
          teams[0].Name.push(t.Name);
        } else if (t.Side === 2) {
          teams[1].Name.push(t.Name);
        }
      });

      teams[0].Name = teams[0].Name.join('/');
      teams[1].Name = teams[1].Name.join('/');
    }

    if (teams.length === 0) {
      teams = [
        {
          Name: b.HomeTeam,
        },
        {
          Name: b.AwayTeam,
        },
      ];
    }

    let br = null;

    if (Array.isArray(dr)) {
      for (const d of dr) {
        br = d && d.Data && d.Data.StakeStatuses ? d.Data.StakeStatuses.find(s => s.StakeID == b.StakeId) : null;
        if (br) break;
      }
    } else {
      br = dr && dr.Data && dr.Data.StakeStatuses ? dr.Data.StakeStatuses.find(s => s.StakeID == b.StakeId) : null;
    }
    let score = null;
    if (br && br.Score) {
      let ss = br.Score.split(',');
      score = ss.map(s => {
        s = s.replace(/[\[\]]/g, '');
        return {
          halfResult: s.split(':'),
        };
      });
    }

    let sn = b.StakeName;
    if (b.Argument) {
      sn = sn + ' ' + b.Argument;
    }

    let bn = b.StakeTypeName;
    if (b.IsDuplicate) {
      bn = 'Ultra Cota';
    }

    if (b.PeriodName) {
      bn = b.PeriodName + ' - ' + bn;
    }

    const betBuilderStakes = [];
    let won = dr != null;
    if (b.BetBuilderStakes) {
      b.BetBuilderStakes.forEach(bbs => {
        let localBr = null;

        if (Array.isArray(dr)) {
          for (const d of dr) {
            localBr = d && d.Data && d.Data.StakeStatuses ? d.Data.StakeStatuses.find(s => s.StakeID == bbs.StakeId) : null;
            if (localBr) break;
          }
        } else {
          localBr = dr && dr.Data && dr.Data.StakeStatuses ? dr.Data.StakeStatuses.find(s => s.StakeID == bbs.StakeId) : null;
        }
        let score = null;
        if (localBr && localBr.Score) {
          let ss = localBr.Score.split(',');
          score = ss.map(s => {
            s = s.replace(/[\[\]]/g, '');
            return {
              halfResult: s.split(':'),
            };
          });
        }

        if (localBr && localBr.StakeStatus !== 2) won = false;

        const stake = {
          ...bbs,
          score,
          StakeStatus: localBr ? stStatus[localBr.StakeStatus].toUpperCase() : 'OPEN',
        };

        betBuilderStakes.push(stake);
      });
    }

    let status = br && br.StakeStatus != null ? stStatus[br.StakeStatus].toUpperCase() : 'OPEN';
    if (b.BetBuilderStakes?.length > 0 && dr != null) {
      if (won) {
        status = 'WON';
      } else {
        status = 'LOST';

        b.BetBuilderStakes.find(bbs => {
          if (bbs.StakeStatus === 'LOST' && bbs.StakeStatus === 'WON') {
            status = 'n/a';
            return true;
          }
          return false;
        });
      }
    }

    const bet = {
      mType: b.MatchIsLive ? 'live' : 'prematch',
      banker: false,
      status: status,
      startAt: moment(b.EventDate).valueOf(),
      idMatch: b.EventId,
      idSport: b.SportId,
      idCategory: b.CountryId,
      idTournament: b.TournamentId,
      idBet: `${b.IsDuplicate ? 'dup:' : ''}${b.StakeTypeId}`,
      idMatchBet: `${b.IsDuplicate ? 'dup:' : ''}${b.EventId}/${b.StakeTypeId}`,
      idBetOutcome: 'UNKNOWN',
      idMatchBetOutcome: `${b.IsDuplicate ? 'dup:' : ''}${b.StakeId}`,
      betDisplayName: bn,
      outcomeDisplayName: sn,
      oddValue: b.Factor,
      specialValue: b.specialValue || '',
      teams: teams.sort(liveTeamSort).map(team => {
        return {
          teamDisplayName: team.Name,
        };
      }),
      // results: b.results.map(r => {
      //     return {
      //         halfName: r.providerResultId,
      //         halfResult: r.matchResultValue.split(":"),
      //     };
      // }),
      results: score ? score : [],
      betBuilderStakes: betBuilderStakes,
      isDuplicate: b.IsDuplicate,
    };

    // handle %player% issue
    /*
    betOutcome:
    betOutcomeDisplayName: "%player%"
    betOutcomeShortName: "%player%"
    idBetOutcome: "8/146/1/player home 5"
    idMatchBetOutcome: "22660061/8/146//Alba, Jordi!35199"
    providerBetOutcomeId: "Alba, Jordi"
    */
    if (bet.outcomeDisplayName === '%player%') {
      // try to get it from state / match data
      if (bst && bst.live && bst.live.matches && typeof bst.live.matches[bet.idMatch] !== 'undefined') {
        const m = bst.live.matches[bet.idMatch];

        const fmb = m.matchBets.find(mb => mb.idMb === bet.idMatchBet);

        if (fmb) {
          const fbo = fmb.mbOutcomes.find(bo => bo.idMbo === bet.idMatchBetOutcome);

          if (fbo && fbo.mboDisplayName) {
            bet.outcomeDisplayName = fbo.mboDisplayName;
          }
        }
      }

      // get it from providerBetOutcomeId
      if (bet.outcomeDisplayName === '%player%' && b.providerBetOutcomeId) {
        bet.outcomeDisplayName = b.providerBetOutcomeId;
      }
    }

    if (bet.banker) bankers += 1;

    ts.push(bet.startAt);

    if (br && br.StakeStatus === 2) {
      won += 1;
    }
    if (br && br.StakeStatus === 3) {
      lost += 1;
    }

    return bet;
  });

  if (!ticket.systemType && ticket.type === 'SISTEM') {
    const parlays = [];
    let events = 0;
    t.ticketCombinationGroups.forEach(c => {
      parlays.push(c.parlays);
      events = c.events;
    });

    ticket.systemType = parlays.join(',') + '/' + events;
    if (bankers) ticket.systemType = bankers + 'B+' + parlays.join(',') + '/' + events;
  }

  let minWinning = Number.MAX_VALUE;
  // t.ticketCombinationGroups.forEach(c => {
  //     if (minWinning > c.minGain) {
  //         minWinning = c.minGain;
  //     }
  // });
  if (minWinning !== Number.MAX_VALUE && minWinning !== ticket.winning) {
    ticket.minWinning = minWinning;
  }

  ticket.betsRes = {
    lost,
    won,
  };

  ticket.maxTime = Math.max(...ts);
  ticket.minTime = Math.max(...ts);

  //debug("normalize live", ticket, t);

  return ticket;
}

function normalizeDigitainLiveTickets(tickets) {
  try {
    if (!tickets || tickets.length === 0) return [];
    return tickets
      .map(t => normalizeDigitainLiveTicket(t))
      .filter(t => t.status === 'OPEN' || t.status === 'LOST' || t.status === 'WON');
  } catch (e) {
    console.error(e);
  }
}

export { normalizeDigitainLiveTicket, normalizeDigitainLiveTickets };
