import { takeLatest, put } from 'redux-saga/effects';

import { deposit } from '../actions/actionTypes';
import ClientAPI from './../../ClientAPI/ClientAPI';
import {
  bridgerPayDepositResponse,
  bridgerPayInitiatedFailed as initiateBridgerPayDepositFailed,
  bridgerPayInitiatedSuccess as initiateBridgerPayDepositSuccess,
  bridgerApplePayInitiatedFailed as initiateBridgerApplePayDepositFailed,
  oktoReceivedCustomer,
  oktoReceivedPaymentCode,
  receivedAircashPaymentLink,
  receivedPlayerPaymentMethods,
  bridgerApplePayDepositResponse,
} from '../actions/deposit';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';

function* initiateBridgerPayDepositSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  const data = action.data;
  try {
    const response = yield axios({
      url: '/api/pay-checkout/initiate-bridger-pay-deposit',
      method: 'post',
      data: data,
    });
    if (!response.status) {
      throw new Error('[Bridger Pay] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[Bridger Pay] Status NOK');
    }
    if (!response.result) {
      throw new Error('[Bridger Pay] Empty result');
    }
    if (!response.result.cashier_token) {
      throw new Error('[Bridger Pay] Empty result');
    }
    yield put(initiateBridgerPayDepositSuccess(response.result));
  } catch (e) {
    console.log('[Bridger Pay Init]', e);
    yield put(initiateBridgerPayDepositFailed());
  }
}

function* initiateBridgerApplePayDepositSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  const state = ClientAPI.getStore().getState();
  const pac = PlayerAbuseChecker.getInfo();
  const data = {
    ...action.data,
    currencyId: 1,
    ...pac,
    deposit_id: ExpiringLocalStorage.get('bridgerApplePayDepositId'),
  };
  try {
    const response = yield axios({
      url: '/api/pay-checkout/initiate-bridger-apple-pay-deposit',
      method: 'post',
      data: data,
    });
    if (!response.status) {
      throw new Error('[Bridger Pay] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[Bridger Pay] Status NOK');
    }
    if (!response.result) {
      throw new Error('[Bridger Pay] Empty result');
    }
    if (!response.result.cashier_token) {
      throw new Error('[Bridger Pay] Empty result');
    }
    yield put(bridgerApplePayDepositResponse(response.result));
  } catch (e) {
    console.log('[Bridger Pay Init]', e);
    yield put(initiateBridgerApplePayDepositFailed());
  }
}

function* finishBridgerPayDepositSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  const { data } = action;
  const state = ClientAPI.getStore().getState();
  // {
  //               amount: this.paymentDetails.amount,
  //               bonusId: this.paymentDetails.bonusId,
  //               card: {
  //                 token: response.token,
  //                 encryptedCVV: response.encryptedCvv,
  //                 bin: response.card.bin,
  //               },
  //             }

  try {
    if (data.card?.bin) {
      const response = yield axios({
        url: '/api/pay-checkout/bridger-pay-fingerprint',
        method: 'post',
        data: {
          currency: state.wallet.currency,
          cardBin: data.card.bin,
        },
      });
      if (!response.status) {
        throw new Error('[Bridger Pay] No status response');
      }

      if (!('OK' === response.status)) {
        throw new Error('[Bridger Pay] Status NOK');
      }
      if (!response.result) {
        throw new Error('[Bridger Pay] Empty result');
      }
      if (!response.result.html) {
        throw new Error('[Bridger Pay] Empty result');
      }
      // create iframe with response.result.html
      const iframe = document.createElement('iframe');
      iframe.srcdoc = response.result.html;
      iframe.setAttribute('style', 'display: none;');
      document.body.prepend(iframe);

      yield put(bridgerPayDepositResponse({ fingerprintReceived: true }));
    } else {
      const cardToken = data?.card?.token;
      if (!cardToken) {
        return;
      }
      let body: any = {
        currencyId: 1,
        amount: data.amount * 100,
        cardToken: cardToken,
        encryptedCvv: data.card.encryptedCVV,
        fingerprint: data.fingerprint,
      };
      if (data.bonusId) {
        body.bonusId = data.bonusId;
      }
      const pac = PlayerAbuseChecker.getInfo();
      body = {
        ...pac,
        ...body,
      };

      const response = yield axios({
        url: '/api/pay-checkout/bridger-pay-deposit',
        method: 'post',
        data: body,
      });
      // {
      //     "status": "OK",
      //     "result": {
      //         "status": "approved",
      //         "has_3ds_challenge": false,
      //         "url_3ds": null
      //     },
      //     "type": "bridger-deposit"
      // }
      if (!response.status) {
        throw new Error('[Bridger Pay] No status response');
      }
      if (!('OK' === response.status)) {
        throw new Error('[Bridger Pay] Status NOK');
      }
      if (!response.result) {
        throw new Error('[Bridger Pay] Empty result');
      }
      if (!response.result.status) {
        throw new Error('[Bridger Pay] Empty result');
      }

      yield put(bridgerPayDepositResponse(response.result));
    }
  } catch (e) {
    console.log('[Bridger Pay Init]', e);
    yield put(bridgerPayDepositResponse({ error: true }));
  }
}

export function* requestOktoCreatePaymentCodeSaga(action: any) {
  const axios = ClientAPI.getInstance();
  try {
    let data = {
      amount: action.amount * 100,
      currencyId: 1,
    };

    if (action.bonusId) {
      // @ts-ignore
      data.bonusId = action.bonusId;
    }

    const pac = PlayerAbuseChecker.getInfo();
    data = {
      ...pac,
      ...data,
    };

    const platformType = window.config.cordova
      ? window.config.native_platform === 'ios'
        ? 3
        : 4
      : window.config.platformType ?? 5;
    // @ts-ignore
    data.platformType = platformType;

    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/okto-create-payment-code',
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Request Okto Create Payment Code response is empty!');
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Request Okto Create Payment Code response has no 'result' property");
    }

    if (response.result.http) {
      throw new Error('[ERROR] Request Okto Create Payment Code response has an http status');
    }

    yield put(oktoReceivedPaymentCode(response.result));
  } catch (error) {
    yield put(oktoReceivedPaymentCode({ error: true }));
    console.log(error);
    return;
  }
}

export function* oktoGetCustomerSaga(action: any) {
  const axios = ClientAPI.getInstance();
  try {
    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/okto-get-customer',
      method: 'GET',
    });

    if (!response) {
      throw new Error('[ERROR] Request Okto GET Customer response is empty!');
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Request Okto GET CUSTOMER response has no 'result' property");
    }

    yield put(oktoReceivedCustomer(response.result));
  } catch (error) {
    return;
  }
}

export function* requestAircashPaymentLinkSaga(action: any) {
  const axios = ClientAPI.getInstance();
  const state = ClientAPI.getStore().getState();
  try {
    const pac = PlayerAbuseChecker.getInfo();
    const platformType = window.config.cordova
      ? window.config.native_platform === 'ios'
        ? 3
        : 4
      : window.config.platformType;

    const data = {
      amount: action.amount * 100,
      bonus_id: action.bonusId,
      currencyCode: state.wallet.currency,
      currencyId: 1,
      languageCode: state.application.language,
      clientId: window.config.clientId,
      ...pac,
      platformType: platformType,
    };

    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/aircash-checkout',
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Payment Checkout response is empty!');
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Payment Checkout response has no 'result' property");
    }

    yield put(receivedAircashPaymentLink(response.result));
  } catch (error) {
    yield put(receivedAircashPaymentLink({ error: true }));
    return;
  }
}
export function* fetchPlayerPaymentMethodsSaga(action: any) {
  const axios = ClientAPI.getInstance();

  try {
    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/get-player-payment-methods',
      method: 'get',
    });
    if (!response) {
      throw new Error('[ERROR] Player payment methods response is empty!');
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Player payment methods response has no 'result' property");
    }

    // if we have a http result, we can add it to the server response
    if (response.result.http) {
      throw new Error('[ERROR] Player payment methods response has an http status');
    }

    yield put(receivedPlayerPaymentMethods(response.result));
  } catch (error) {
    yield put(receivedPlayerPaymentMethods({ error: true }));
    return;
  }
}

export default function* watchDeposit() {
  yield takeLatest(deposit.INITIATE_BRIDGER_PAY_DEPOSIT, initiateBridgerPayDepositSaga);
  yield takeLatest(deposit.FINISH_BRIDGER_PAY_DEPOSIT, finishBridgerPayDepositSaga);

  yield takeLatest(deposit.INITIATE_BRIDGER_APPLE_PAY_DEPOSIT, initiateBridgerApplePayDepositSaga);

  yield takeLatest(deposit.OKTO_CREATE_PAYMENT_CODE, requestOktoCreatePaymentCodeSaga);
  yield takeLatest(deposit.OKTO_GET_CUSTOMER, oktoGetCustomerSaga);

  yield takeLatest(deposit.AIRCASH_REQUEST_PAYMENT_LINK, requestAircashPaymentLinkSaga);

  yield takeLatest(deposit.FETCH_PLAYER_PAYMENT_METHODS, fetchPlayerPaymentMethodsSaga);
}
