/*
type CurrencyArtBundleImage = {
  name: string;
  value: string;
};

type CurrencyArtBundleTexts = {
  text_display_name: string | { [key: string]: string };
  text_display_name_short: string | { [key: string]: string };
};

type CurrencyArtBundle = {
  attributes?: any[];
  images?: CurrencyArtBundleImage[];
  texts?: CurrencyArtBundleTexts[];
  text_display_name: CurrencyArtBundleTexts;
  text_display_name_short: CurrencyArtBundleTexts;
  small_square_ar11_icon_transparent: string;
};

type CurrencyProps = {
  description: string;
  currency_code: string;
  metadata: any | null | undefined;
  updated_at: string;
  icon_position: string;
  format: string;
  class_type: string;
  art_bundle: CurrencyArtBundle[];
};

type PrizeProps = {
  id: number;
  claim_end_time: string;

  reward_class_type: string;
  reward_type: string;
  reward_currency: string;
  reward_value: number;

  reward_metadata: any | null | undefined;
  meta: any | null | undefined;

  art_bundle: any[];
  currency: CurrencyProps;
};
*/

// %v - valoare numerica (50)
// %i - icon
// %n - nume - Dolari Americani
// %sn - short name $

// "%i %n %v" // [icon] 50 Dolari Americani
// "%v%sn"// 50$

// [
//   {
//     type: "icon",
//     value: "https://cdn.iconscout.com/icon/free/png-256/bitcoin-1838368-1557409.png"
//   },
//   {
//     type: "number",
//     value: "50"
//   },
//   {
//     type: "string",
//     value: "xxx",
//   },
//   {
//     type: "space",
//   }
// ]

const prizeCurrencyFormatOld = (currency, reward_value) => {
  // const currency = prize.currency;

  if (!currency?.format) return '';

  const arb = currency?.art_bundle[0];

  if (!arb) return '';

  // console.log('prizeCurrencyFormat', { currency, arb, prize });

  switch (currency.format) {
    case '%v %n': {
      return {
        currency: arb.text_display_name,
        value: reward_value,
        valueOrder: 1,
        currencyOrder: 2,
      };
    }
    case '%n %v': {
      return {
        currency: arb.text_display_name,
        value: reward_value,
        valueOrder: 2,
        currencyOrder: 1,
      };
    }

    case '%v%n': {
      return {
        currency: arb.text_display_name_short,
        value: reward_value,
        valueOrder: 1,
        currencyOrder: 2,
      };
    }
    case '%n%v': {
      return {
        currency: arb.text_display_name_short,
        value: reward_value,
        valueOrder: 2,
        currencyOrder: 1,
      };
    }

    default:
      return {
        currency: '',
        value: reward_value,
        valueOrder: 2,
        currencyOrder: 1,
      };
  }
};

const parse = (fmt, value, defaultName, arb) => {
  const pm = fmt.match(/(%[ivsn%]{1}|\s|([^\s]+))/g);

  let r = [];

  for (const m of pm) {
    if (m === '%%') {
      r.push({
        type: 'string',
        value: '%',
      });
    } else if (m === '%i') {
      r.push({
        type: 'icon',
        value: arb ? arb.small_square_ar11_icon_transparent : '',
      });
    } else if (m === '%v') {
      r.push({
        type: 'number',
        value,
      });
    } else if (m === '%s') {
      r.push({
        type: 'string',
        value: arb ? arb.text_display_name_short?.text : defaultName,
      });
    } else if (m === '%n') {
      r.push({
        type: 'string',
        value: arb ? arb.text_display_name?.text : defaultName,
      });
    } else {
      if (m === ' ') {
        r.push({
          type: 'space',
        });
      } else {
        r.push({
          type: 'string',
          value: m + '',
        });
      }
    }
  }

  return r;
};

const prizeCurrencyFormat = (currency, reward_value) => {
  const arb = currency?.art_bundle?.[0];

  const defaultName = arb ? arb.text_display_name?.text : currency?.currency_code;

  const defaultFormat = [
    {
      type: 'number',
      value: reward_value,
    },
    {
      type: 'space',
    },
    {
      type: 'string',
      value: defaultName,
    },
  ];

  if (!currency?.format) return defaultFormat;

  if (!arb) return defaultFormat;

  return parse(currency?.format, reward_value, defaultName, arb);
};

export default prizeCurrencyFormat;
