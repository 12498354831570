import React, { useContext } from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash-es';
import { useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

import genericDataSource from '../../utils/generic-data-source-api-cache';
import { useProcessList } from '../utils/useProcessList';

const defaultProps = {
  className: '',
  styleText: `
  width: 100%;
  height: auto;
  `,
  properties: {
    dsType: '',
    deId: '',
  },
};

interface DataSourceProps {
  className: string;
  $styleText: string;
  style?: any;
  properties?: {
    dsType: string;
    deId: string;
  };
  children?: JSX.Element;
}

const DataSourceDiv = styled.div<DataSourceProps>((props) => props.$styleText);

export const DataSource = (componentProps: any) => {
  let props = componentProps;

  const ref = React.useRef<any>(null);

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const [__id] = React.useState(uniqueId('unique-'));
  const { i18n } = useTranslation();

  const dsId = props.properties?.dsId ?? '';

  const filterKey = props.properties.key ?? null;
  let filterValue = props.properties.value ?? null;
  const valueFromParam = props.properties.valueFromParam ?? false;
  const matches = useMatches();

  if (valueFromParam && props?.properties?.pathParamKey) {
    if (matches && matches.length) {
      const match = matches[0];
      let pathParamKey = props.properties.pathParamKey;
      if (pathParamKey != null && pathParamKey[0] === ':') {
        pathParamKey = pathParamKey.slice(1);
      }
      if (match.params && match.params[pathParamKey] != null) {
        filterValue = match.params[pathParamKey];
      }
    }
  }

  let data: any[] = [];
  let loading = true;
  let loaded = false;

  const dataSource: any = genericDataSource({
    dsId,
    filterKey,
    filterValue,
    limit: null,
  });

  if (dsId && dataSource) {
    if (dataSource?.data?.data && dataSource?.data?.data?.length) {
      data = dataSource.data.data;
    }
    loading = dataSource.loading;
    loaded = dataSource.loaded;
  }

  //console.log(`DataSource[${dsId}]`, dsId, dataSource?.data);

  const { data: ds } = useProcessList(
    dataSource?.data,
    i18n,
    dsId,
    props.properties.items && !isNaN(Number(props.properties.items)) ? Number(props.properties.items) : 0,
  );

  const contextValue = React.useMemo(() => {
    if (ds.length === 0) {
      const parent = ref?.current?.closest('.parent-scroller');
      if (parent) {
        parent.classList.add('d-none');
      }
    } else {
      const parent = ref?.current?.closest('.parent-scroller');
      if (parent) {
        parent.classList.remove('d-none');
      }
    }

    return {
      ...dataElementContext,
      dsList: ds,
      __loading: loading,
      __loaded: loaded,
      __id,
    };
  }, [componentProps, ds, dataElementContext]);

  if (!isVisible) return null;

  //console.log('contextValue', contextValue, data);

  return (
    <DataSourceDiv ref={ref} className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </DataSourceDiv>
  );
};
