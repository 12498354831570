import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { DataElementContext } from '../../../../../../page-components/common/DataElementContext';
import { PostRepository, PostContentType, FileRepository } from '@amityco/ts-sdk';

import { PostType, SocialCapability, SocialHubUser } from '../../..';
import EmojiPicker, { Categories, EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import LOG from '../../../utils/Log';

const AMITY_COMMUNITY_ID = window.config.AMITY_MAIN_COMMUNITY_ID;

type PostProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const NewPost = (componentProps: PostProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const { i18n, t } = useTranslation();
  moment.locale(i18n.language);

  const dataElementContext = React.useContext(DataElementContext);
  const {
    isWidget,
    currentUser,
    selectedNewPostType,
    newPostSubmissionSuccess,
  }: {
    isWidget: boolean;
    currentUser: SocialHubUser;
    selectedNewPostType: PostType;
    newPostSubmissionSuccess: boolean;
  } = dataElementContext;

  // ---------------------------------------------- STATE ----------------------------------------------

  const [isSubmenuOpen, setIsSubmenuOpen] = React.useState(false);
  const [postText, setPostText] = React.useState('');
  const [isPostSubmitting, setIsPostSubmitting] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [isUploadInProgress, setIsUploadInProgress] = React.useState(false);
  const [uploadError, setUploadError] = React.useState(false);

  const canPostImage = currentUser.capabilities.includes(SocialCapability.FEED_CAN_POST_IMAGE);
  const canPostVideo = currentUser.capabilities.includes(SocialCapability.FEED_CAN_POST_VIDEO);
  const canPostPoll = currentUser.capabilities.includes(SocialCapability.FEED_CAN_POST_POLL);

  // ---------------------------------------------- HOOKS ----------------------------------------------

  const onSelectNewPostType = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.posttype != null && e.currentTarget.dataset.posttype !== '') {
        const postType = e.currentTarget.dataset.posttype as PostType;
        dataElementContext.setSelectedNewPostType(postType);
        dataElementContext.setSelectedTab('newpost');
      }
    },
    [dataElementContext],
  );

  const createPost = React.useCallback(
    (text: string, fileIds?: string[]) => {
      if (!canPostImage) return;

      const newPost = {
        data: {
          text,
        },
        targetType: 'community',
        targetId: AMITY_COMMUNITY_ID,
        ...(fileIds?.length && {
          attachments: fileIds.map((fileId) => ({ type: selectedNewPostType, fileId })),
        }),
      };

      setIsPostSubmitting(true);
      setIsEmojiPickerOpen(false);

      PostRepository.createPost(newPost)
        .then((post) => {
          LOG.ok('SENT POST', post);
          setPostText('');

          dataElementContext.setSelectedTab('feed');
          dataElementContext.setNewPostSubmissionSuccess(true);
        })
        .catch((error) => {
          console.error('📝❌ ERROR SENDING POST', error);
        })
        .finally(() => {
          setIsPostSubmitting(false);
        });
    },
    [canPostImage, dataElementContext, selectedNewPostType],
  );

  const uploadFiles = React.useCallback(
    (onSuccess: (fileIds: string[]) => void) => {
      if (!selectedFiles.length) return;

      const data = new FormData();
      selectedFiles.forEach((file) => data.append('files', file));

      setIsUploadInProgress(true);

      switch (selectedNewPostType) {
        case PostType.IMAGE:
          // Upload the files to amity
          FileRepository.uploadImage(data)
            .then((response) => {
              LOG.ok('IMAGES UPLOAD SUCCESS', response);
              const fileIds = response.data.map((uploaded) => uploaded.fileId);
              onSuccess(fileIds);
            })
            .catch((error) => {
              LOG.error('ERROR UPLOADING IMAGES', error);
              setUploadError(true);
            })
            .finally(() => setIsUploadInProgress(false));
          break;
        case PostType.VIDEO:
          // Upload the files to amity
          FileRepository.uploadVideo(data)
            .then((response) => {
              LOG.ok('VIDEO UPLOAD SUCCESS', response);
              const fileIds = response.data.map((uploaded) => uploaded.fileId);
              onSuccess(fileIds);
            })
            .catch((error) => {
              LOG.error('ERROR UPLOADING VIDEO', error);
              setUploadError(true);
            })
            .finally(() => setIsUploadInProgress(false));
          break;
      }
    },
    [selectedFiles, selectedNewPostType],
  );

  //# POST SUBMIT
  const onPostSubmit = React.useCallback(() => {
    // Check if there are files to upload frst, and only after we allow the post to be sent
    if (selectedFiles.length > 0 && !isUploadInProgress) {
      LOG.info('UPLOADING FILES');
      uploadFiles((fileIds) => {
        createPost(postText, fileIds);
      });
      return;
    }

    createPost(postText);
  }, [createPost, selectedFiles.length, isUploadInProgress, postText, uploadFiles]);

  // --------------------- Message input stuff ---------------------

  const messageInputRef = React.useRef<HTMLTextAreaElement | null>(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = React.useState<boolean>(false);

  // Recalculate message box height
  React.useEffect(() => {
    if (!messageInputRef.current) return;
    messageInputRef.current.style.height = '';
    messageInputRef.current.style.height = messageInputRef.current.scrollHeight + 'px';
  }, [postText]);

  //# EVENT: input change
  const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPostText(e.target.value);
    setIsEmojiPickerOpen(false);
  }, []);

  //# EVENT: emoji picker toggler click
  const onEmojiPickerTogglerClick = React.useCallback(() => {
    setIsEmojiPickerOpen((isEmojiPickerOpen) => !isEmojiPickerOpen);
    messageInputRef.current?.focus();
  }, []);

  //# EVENT: emoji select
  const onEmojiSelect = React.useCallback((emojiData: EmojiClickData) => {
    if (!messageInputRef.current) return;

    const { selectionStart, selectionEnd } = messageInputRef.current;

    if (messageInputRef.current.value.length + emojiData.emoji.length <= 250)
      messageInputRef.current.setRangeText(emojiData.emoji, selectionStart, selectionEnd, 'end');

    setPostText(messageInputRef.current.value);
    // setIsEmojiPickerOpen(false);

    messageInputRef.current.focus();
  }, []);

  // Textarea reference
  React.useEffect(() => {
    if (!messageInputRef.current) {
      messageInputRef.current = document.querySelector('textarea[name=messageinput]') as HTMLTextAreaElement;
      if (messageInputRef.current) messageInputRef.current.maxLength = 250;
    }
  }, []);

  //! VIDEO CONTAINER
  const [mediaContainer, setMediaContainer] = React.useState<HTMLElement>();
  React.useEffect(() => {
    if (selectedNewPostType !== PostType.VIDEO) return;
    const container = document.querySelector('.video-container') as HTMLDivElement;
    LOG.info('📝📹 VIDEO CONTAINER', container);
    if (!container) return;
    setMediaContainer(container);
  }, [selectedFiles, selectedNewPostType]);

  // ---------------------------------------------- CONTEXT ----------------------------------------------

  const contextValue = React.useMemo(() => {
    return {
      author: {
        avatarCustomUrl: currentUser.avatarUrl,
        displayName: currentUser.displayName,
      },
      isWidget,
      isSubmenuOpen,
      onSelectNewPostType,
      selectedNewPostType,
      newPostSubmissionSuccess,
      onEmojiPickerTogglerClick,
      onPostSubmit,
      onInputChange,
      uploadError,
      isPostSubmitting: isPostSubmitting || isUploadInProgress,
      submitPostButtonState: postText.trim().length > 0 ? 'default' : 'disabled',
      selectedFiles: selectedFiles.map((file, index) => ({
        url: URL.createObjectURL(file),
        index,
        fileRemoveHandler: (index: number) => setSelectedFiles(selectedFiles.filter((_, i) => i !== index)),
      })),
      canPostImage,
      canPostVideo,
      canPostPoll,

      handleDismissDialog: () => dataElementContext.setNewPostSubmissionSuccess(false),
      toggleSubmenu: () => setIsSubmenuOpen(!isSubmenuOpen),
      onPostCancelClick: () => dataElementContext.setSelectedTab('feed'),
      fileChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) =>
        setSelectedFiles([...(event.target.files ?? [])]),
    };
  }, [
    currentUser.avatarUrl,
    currentUser.displayName,
    dataElementContext,
    isPostSubmitting,
    isSubmenuOpen,
    isUploadInProgress,
    isWidget,
    newPostSubmissionSuccess,
    onEmojiPickerTogglerClick,
    onInputChange,
    onPostSubmit,
    onSelectNewPostType,
    postText,
    selectedFiles,
    selectedNewPostType,
    uploadError,
    canPostImage,
    canPostVideo,
    canPostPoll,
  ]);
  // LOG('SocialHubNewPost[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>

      {mediaContainer &&
        selectedFiles.length > 0 &&
        createPortal(
          <video
            // poster={(post as VideoPostData).videoThumbnailUrl}
            src={URL.createObjectURL(selectedFiles[0])}
            width="100%"
            onLoadedMetadata={(e) => {
              const v = e.target as HTMLVideoElement;
              const aspectRatio = v.videoWidth / v.videoHeight;
              const height = v.offsetWidth / aspectRatio;
              v.style.height = `${height}px`;
              LOG.info('📝📹 VIDEO LOADED', v.videoWidth, v.videoHeight, aspectRatio, height);
            }}
            controls
          >
            <track kind="captions" />
          </video>,
          mediaContainer,
        )}

      <EmojiPicker
        open={isEmojiPickerOpen}
        width="100%"
        height="236px"
        previewConfig={{ showPreview: false }}
        onEmojiClick={onEmojiSelect}
        skinTonesDisabled={true}
        emojiStyle={EmojiStyle.NATIVE}
        lazyLoadEmojis={true}
        autoFocusSearch={false}
        searchPlaceHolder={t('Search')}
        style={{
          position: 'absolute',
          bottom: `${messageInputRef.current ? parseFloat(window.getComputedStyle(messageInputRef.current).height) + 36 : 0}px`,
          left: 0,
          right: 0,
        }}
        categories={[
          {
            category: Categories.SUGGESTED,
            name: t('Recently Used'),
          },
          {
            category: Categories.SMILEYS_PEOPLE,
            name: t('Smileys & People'),
          },
          {
            category: Categories.ANIMALS_NATURE,
            name: t('Animals & Nature'),
          },
          {
            category: Categories.FOOD_DRINK,
            name: t('Food & Drink'),
          },
          {
            category: Categories.TRAVEL_PLACES,
            name: t('Travel & Places'),
          },
          {
            category: Categories.ACTIVITIES,
            name: t('Activities'),
          },
          {
            category: Categories.OBJECTS,
            name: t('Objects'),
          },
          {
            category: Categories.SYMBOLS,
            name: t('Symbols'),
          },
          {
            category: Categories.FLAGS,
            name: t('Flags'),
          },
        ]}
      />
    </ModuleElementDiv>
  );
};

export default NewPost;
