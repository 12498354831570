const styles: { [key: string]: string[] } = {
  base: ['color: #fff', 'background-color: #555', 'padding: 1px 4px', 'border-radius: 2px', 'font-weight: bold'],
  info: ['color: #186399', 'background-color: skyblue'],
  success: ['background-color: green'],
  warning: ['color: #79310B', 'background-color: #FFE5BE'],
  error: ['background-color: red'],
};

const logStyled = (style: string[], ...message: any[]) => {
  if (window.config.environment != 'staging') return;

  const combinedStyle = styles.base.concat(style).join(';') + ';';
  const styledMessage = message.filter((msg) => typeof msg === 'string').join(' ');
  const nonStyledMessage = message.filter((msg) => typeof msg !== 'string');

  console.log(`%c${styledMessage}`, combinedStyle, ...nonStyledMessage);
};

const LOG = (...message: any[]) => {
  logStyled([], ...message);
};

LOG.info = (...message: any[]) => {
  logStyled(styles.info, ...message);
};

LOG.ok = (...message: any[]) => {
  logStyled(styles.success, '✅', ...message);
};

LOG.warn = (...message: any[]) => {
  logStyled(styles.warning, '⚠️', ...message);
};

LOG.error = (...message: any[]) => {
  logStyled(styles.error, '❌', ...message);
};

export default LOG;
