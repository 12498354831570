import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { generateDateRange } from './utils/functions';
import { loadCalendarStatus, openPrize, collectPrize } from '../../../modules/promo-calendar/store/actions/calendar';
import { getText } from '../../../modules/lobby/utils/functions';
import { useMediaQuery } from '../../utils/useQueryMedia';

type CalendarProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Calendar = (componentProps: CalendarProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const calendarStatus = useAppSelector<any>((state) => state.promoCalendar?.calendar?.status ?? null);
  const calendarLoading = useAppSelector((state) => state.promoCalendar?.calendar?.loading ?? false);
  const calendarCollecting = useAppSelector((state) => state.promoCalendar?.calendar?.collecting ?? false);
  const calendarOpening = useAppSelector((state) => state.promoCalendar?.calendar?.opening ?? false);

  const [openMainView, setOpenMainView] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedBonus, setSelectedBonus] = React.useState<string | null>(null);

  const isDesktop = useMediaQuery('(min-width:900px)');

  const onSelectBonus = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    if (e.currentTarget.dataset.bonusId) {
      const bonusId = e.currentTarget.dataset.bonusId;
      setSelectedBonus(bonusId);
    }
  }, []);

  const onToggleMainView = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
        navigate('/login');
        return;
      }
      setOpenMainView((v) => !v);
    },
    [authentication],
  );
  const onToggleDialog = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    setOpenDialog((v) => !v);
  }, []);

  const handlePrizeOpen = React.useCallback(() => {
    if (calendarStatus?.current_prize?.opened === false) {
      dispatch(openPrize(calendarStatus.current_prize.id));
    }
  }, [calendarStatus]);

  React.useEffect(() => {
    if (authentication && authentication.access_token != null) {
      dispatch(loadCalendarStatus());
    }
  }, [authentication.access_token]);

  const onCollect = React.useCallback(() => {
    setOpenDialog(false);
    setOpenMainView(false);
    if (selectedBonus) {
      navigate(`/deposit?bonusId=${selectedBonus}`);
    } else {
      navigate('/deposit');
    }
  }, [selectedBonus]);

  const onClose = React.useCallback(() => {
    if (calendarStatus?.current_prize?.collected === false) {
      dispatch(collectPrize(calendarStatus?.current_prize?.id));
    }
    if (calendarStatus?.current_prize?.meta?.is_deposit) {
      let bonusId = '';
      const bonusIds: any[] = [];

      if (calendarStatus?.current_prize?.meta?.bonuses) {
        const bonuses = calendarStatus?.current_prize?.meta?.bonuses;

        if (Array.isArray(bonuses) && bonuses.length > 0) {
          bonuses.forEach((bonus) => {
            if (bonus?.bonus_preset_id) {
              bonusIds.push(bonus.bonus_preset_id);
            }
          });
        }
        if (bonusIds.length === 1) {
          bonusId = bonusIds[0];
        }
      }

      if (bonusIds.length === 1) {
        setOpenMainView(false);
        navigate(`/deposit?bonusId=${bonusId}`);
      } else if (bonusIds.length > 1) {
        setOpenDialog(true);
      }
    }
  }, [calendarStatus, navigate]);

  const contextValue = React.useMemo(() => {
    const status = JSON.parse(JSON.stringify(calendarStatus));
    if (status?.campaign?.meta?.ui?.cell_1_text)
      status.campaign.meta.ui.cell_1_text = getText(status, `campaign.meta.ui.cell_1_text.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.cell_1_value)
      status.campaign.meta.ui.cell_1_value = getText(status, `campaign.meta.ui.cell_1_value.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.cell_2_text)
      status.campaign.meta.ui.cell_2_text = getText(status, `campaign.meta.ui.cell_2_text.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.cell_2_value)
      status.campaign.meta.ui.cell_2_value = getText(status, `campaign.meta.ui.cell_2_value.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.cell_3_text)
      status.campaign.meta.ui.cell_3_text = getText(status, `campaign.meta.ui.cell_3_text.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.cell_3_value)
      status.campaign.meta.ui.cell_3_value = getText(status, `campaign.meta.ui.cell_3_value.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.cell_4_text)
      status.campaign.meta.ui.cell_4_text = getText(status, `campaign.meta.ui.cell_4_text.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.cell_4_value)
      status.campaign.meta.ui.cell_4_value = getText(status, `campaign.meta.ui.cell_4_value.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.name)
      status.campaign.meta.ui.name = getText(status, `campaign.meta.ui.name.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.rules)
      status.campaign.meta.ui.rules = getText(status, `campaign.meta.ui.rules.${i18n.language}`, '');

    if (status?.current_prize?.meta?.ui?.bonus_title)
      status.current_prize.meta.ui.bonus_title = getText(
        status,
        `current_prize.meta.ui.bonus_title.${i18n.language}`,
        '',
      );
    if (status?.current_prize?.meta?.ui?.bonus_subtitle)
      status.current_prize.meta.ui.bonus_subtitle = getText(
        status,
        `current_prize.meta.ui.bonus_subtitle.${i18n.language}`,
        '',
      );

    if (status?.current_prize?.meta?.ui?.bonus_title_2)
      status.current_prize.meta.ui.bonus_title_2 = getText(
        status,
        `current_prize.meta.ui.bonus_title_2.${i18n.language}`,
        '',
      );
    if (status?.current_prize?.meta?.ui?.bonus_subtitle_2)
      status.current_prize.meta.ui.bonus_subtitle_2 = getText(
        status,
        `current_prize.meta.ui.bonus_subtitle_2.${i18n.language}`,
        '',
      );
    if (status?.current_prize?.meta?.ui?.tickets_title)
      status.current_prize.meta.ui.tickets_title = getText(
        status,
        `current_prize.meta.ui.tickets_title.${i18n.language}`,
        '',
      );
    if (status?.current_prize?.collect_start_time)
      status.current_prize.collect_start_time = moment(calendarStatus?.current_prize?.collect_start_time ?? null)
        .locale(i18n.language)
        .format('DD MMM YYYY');
    if (status?.campaign?.meta?.ui?.lobby_title)
      status.campaign.meta.ui.lobby_title = getText(status, `campaign.meta.ui.lobby_title.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.lobby_subtitle)
      status.campaign.meta.ui.lobby_subtitle = getText(status, `campaign.meta.ui.lobby_subtitle.${i18n.language}`, '');
    if (status?.campaign?.meta?.ui?.cta_text)
      status.campaign.meta.ui.cta_text = getText(status, `campaign.meta.ui.cta_text.${i18n.language}`, '');

    let state = 'pending';
    if (!calendarStatus?.current_prize?.meta && !calendarCollecting && !calendarOpening) {
      state = 'error';
    }

    if (calendarStatus?.current_prize?.collected === true) {
      state = 'success';
    }

    if (
      calendarStatus?.current_prize?.collected === true &&
      calendarStatus?.current_prize?.activated != null &&
      !calendarStatus?.current_prize?.activated != null
    ) {
      state = 'warning';
    }

    let loggedIn = true;
    if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      loggedIn = false;
    }

    let hasTwoBonuses = false;
    if (calendarStatus?.current_prize?.meta?.bonuses) {
      const bonuses = calendarStatus?.current_prize?.meta?.bonuses;
      let bonusNo = 0;
      if (Array.isArray(bonuses) && bonuses.length > 0) {
        bonuses.forEach((bonus) => {
          if (bonus?.bonus_preset_id) {
            bonusNo += 1;

            if (bonusNo === 1 && status.current_prize.meta.ui) {
              status.current_prize.meta.ui.bonus_id_1 = bonus.bonus_preset_id;
            } else if (bonusNo === 2 && status.current_prize.meta.ui) {
              status.current_prize.meta.ui.bonus_id_2 = bonus.bonus_preset_id;
            }
          }
        });
      }
      if (bonusNo > 1) {
        hasTwoBonuses = true;
      }
    }

    return {
      days: generateDateRange(i18n.language),
      openMainView,
      onToggleMainView,
      openDialog,
      onToggleDialog,
      dialogState: state,
      calendarStatus: status,
      calendarLoading,
      calendarCollecting,
      calendarOpening,
      dialogOnCollect: onCollect,
      dialogOnClose: onClose,
      loggedIn,
      currentDay: generateDateRange(i18n.language).filter((day) => day.current === true)[0] ?? null,
      hasTwoBonuses,
      handlePrizeOpen,
      selectedBonus,
      onSelectBonus,
      isDesktop,
    };
  }, [
    openMainView,
    onToggleMainView,
    openDialog,
    onToggleDialog,
    calendarStatus,
    calendarLoading,
    i18n,
    onCollect,
    onClose,
    handlePrizeOpen,
    selectedBonus,
    onSelectBonus,
    isDesktop,
  ]);

  // console.log('Calendar[contextValue]: ', { contextValue, authentication, calendarLoading });
  if (!contextValue?.calendarStatus || !contextValue?.loggedIn || !authentication?.access_token) return null;

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Calendar;
