// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';
import pragmaticLive from '../sagas/pragmaticLiveWS';

// import { RootState } from '../index';

const DEBUG = true;

export const subscribeToTable = (tableId: string, currency: string) => {
  pragmaticLive.subscribe(tableId, currency);
};

export interface PragmaticLiveReducer {
  tables: any;
  tableKey: string[];
}

// mainEventBus.emit("NOTIFICATION", {message: `You have bought {{VALUE}}`, placeholders: {VALUE: 'Small pack'}, progressBar:false, type: 'success'})

export const pragmaticLiveSlice = createSlice({
  name: 'pragmaticLive',
  initialState: <PragmaticLiveReducer>{
    tables: {},
  },
  reducers: {
    clearState: (state, action) => {
      state.tables = {};
    },
    updateTables: (state, action) => {
      if (action?.payload?.tableId) {
        state.tables[action.payload.tableId] = action.payload;
      }
    },
    updateTablesKeys: (state, action) => {
      if (action?.payload?.tableKey) {
        state.tableKey = action?.payload?.tableKey;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const { clearState, updateTables, updateTablesKeys } = pragmaticLiveSlice.actions;

export default pragmaticLiveSlice.reducer;
