import React from 'react';

import './index.scss';

const PageLoader = () => {
  const style = {
    backgroundColor: window.config.loaderBackgroundColor ?? '#fff',
  };
  return (
    <div className="page-loader w-100 h-100" style={style}>
      <img src={window.config.loaderImage} alt="loader" />
      <div className="text">{window.config.loaderText}</div>
    </div>
  );
};

export default PageLoader;
