import React from 'react';
import styled from 'styled-components';
import { produce } from 'immer';

import { useAppSelector } from '../../store';
import craftJsParser from '../../components/utils/craftJsParser';
import { DataElementContext } from '../common/DataElementContext';
import { evaluatePropFunction } from '../utils/evaluatePropFunction';
import { propsApplyLinksValues } from '../utils/applyLinksValues';
import { LayoutContext } from '../common/LayoutContext';
import MainEventBus from '../../utils/evbus';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    selectedLayout: '',
    layoutStates: [],
  },
  visibility: {},
};

const TabsLayoutDiv = styled.div((props) => props.$styleText);

export const TabsLayout = (componentProps) => {
  let props = componentProps;

  const pageDataContext = React.useContext(PageDataContext);
  const context = React.useContext(DataElementContext);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user'),
  );

  if (context && componentProps?.properties['links'] && Object.values(componentProps?.properties['links']).length) {
    props = produce(componentProps, (draft) => {
      propsApplyLinksValues(draft, context);
    });
  }

  if (componentProps?.properties?.['propFunction'] != null && componentProps?.properties?.['propFunction']) {
    props = produce(props, (draft) => {
      evaluatePropFunction(draft, context);
    });
  }

  let { selectedLayout, layoutStates } = props.properties;
  const [state, setState] = React.useState(selectedLayout);

  const switchLayout = (newLayout) => {
    setState(newLayout);
    if (props.properties.emitTabChangedEvent && typeof props.properties.emitTabChangedEvent === 'function') {
      props.properties.emitTabChangedEvent(newLayout);
    }
  };

  let tabs = null;
  let tabContent = null;
  let hasError = false;

  try {
    const data = getProjectDataFromContext(pageDataContext);

    const tabsNodeId = props.linkedNodes['tabsContainer'];
    if (!tabsNodeId) return null;

    const tabContentNodeId = props.linkedNodes['tabContainer'];
    if (!tabContentNodeId) return null;

    tabs = craftJsParser({
      craftState: data,
      rootNodeId: tabsNodeId,
      pageId: props.refType === 'layout' ? null : props.refId,
      pageLayoutId: props.refType === 'layout' ? props.refId : null,
      pageType: props.refType,
      options: {
        authenticated,
      },
    });
    tabContent = craftJsParser({
      craftState: data,
      rootNodeId: tabContentNodeId,
      pageId: props.refType === 'layout' ? null : props.refId,
      pageLayoutId: props.refType === 'layout' ? props.refId : null,
      pageType: props.refType,
      options: {
        authenticated,
      },
    });
  } catch (err) {
    console.error(err);
    hasError = true;
  }

  const externalSwitchLayout = (data) => {
    //console.log('externalSwitchLayout', data);

    if (data) {
      let exists = layoutStates.find((s) => s.value === data);
      if (exists) {
        switchLayout(data);
      }
    }
  };

  React.useEffect(() => {
    MainEventBus.on('TAB-CHANGE', externalSwitchLayout);
    return () => {
      MainEventBus.remove('TAB-CHANGE', externalSwitchLayout);
    };
  }, []);

  if (hasError) return null;
  if (!tabs) return null;
  if (!tabContent) return null;

  return (
    <TabsLayoutDiv data-is-node="true" data-is-lsbase="true" $styleText={props.styleText} style={props.style}>
      <LayoutContext.Provider value={{ selectedLayout: state, layoutStates, switchLayout }}>
        {tabs}
        {tabContent}
      </LayoutContext.Provider>
    </TabsLayoutDiv>
  );
};
