import React from 'react';
import styled from 'styled-components';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';

import { MessageRepository, MessageContentType } from '@amityco/ts-sdk';
import { useAppSelector, useAppDispatch } from '@/store';
import { ChannelMember, PinnedMessageType, SocialCapability, SocialHubUser } from '..';
import { fetchCampaign } from '@/store/slices/airdrop';

import './index.scss';
import LOG from '../utils/Log';
import { useTranslation } from 'react-i18next';
import { encodeTagsClient, formatDuration } from '../utils/functions';

const AMITY_CHANNEL_ID = window.config.AMITY_MAIN_CHANNEL_ID;

type ChatProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Chat = (componentProps: ChatProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const { t } = useTranslation();

  //# CONTEXT
  const dataElementContext = React.useContext(DataElementContext);
  const currentUser: SocialHubUser = dataElementContext.currentUser;
  // const members: Amity.LiveCollection<Amity.Membership<'channel'>> = dataElementContext.channelMembers;
  const members: ChannelMember[] = dataElementContext.channelMembers;
  const messages: Amity.LiveCollection<Amity.Message> = dataElementContext.channelMessages;
  const pinnedMessage = dataElementContext.pinnedMessage;
  const setPinnedMessage = dataElementContext.setPinnedMessage;

  // App store
  const campaign = useAppSelector((state) => state.airdrop.campaign);
  const dispatch = useAppDispatch();

  const intervalRef = React.useRef<NodeJS.Timeout>();
  const [ratelimitTimer, setRatelimitTimer] = React.useState<number>(0);
  const [replyingTo, setReplyingTo] = React.useState<{ messageId: string; name: string; message: string } | null>(null);

  //# MESSAGE SEND
  const sendMessage = React.useCallback(
    (messageText: string) => {
      if (replyingTo?.messageId) setReplyingTo(null);

      const message = {
        subChannelId: AMITY_CHANNEL_ID,
        dataType: MessageContentType.TEXT,
        data: {
          text: messageText,
        },
        parentId: replyingTo?.messageId,
        metadata: {
          reply: replyingTo,
        },
      };

      shouldScrollRef.current = true;

      MessageRepository.createMessage(message)
        .then((message) => {
          LOG.ok('SENT MESSAGE', message);
        })
        .catch((error) => {
          LOG.error(error);

          const match = error.message.match(/available in (\d+)ms/);
          const time: number = match ? match[1] : 0;
          const seconds = Math.ceil(time / 1000);
          let timer = seconds;
          setRatelimitTimer(timer);

          const oldPinnedMessage = pinnedMessage;

          setPinnedMessage({
            type: PinnedMessageType.WARN,
            text: t('Try again in') + ' ' + timer + ' ' + t('seconds'),
          });

          if (intervalRef.current) clearInterval(intervalRef.current);
          intervalRef.current = setInterval(() => {
            timer--;
            setPinnedMessage({
              type: PinnedMessageType.WARN,
              text: t('Try again in') + ' ' + timer + ' ' + t('seconds'),
            });
            if (timer <= 0) {
              clearInterval(intervalRef.current);
              setPinnedMessage(oldPinnedMessage);
            }
            setRatelimitTimer(timer);
          }, 1000);
        });
    },
    [pinnedMessage, setPinnedMessage, t, replyingTo],
  );

  //# MESSAGE REPLY
  const handleMessageReply = React.useCallback((messageId: string, name: string, message: string) => {
    if (!messageId) return;
    setReplyingTo({ messageId, name, message: encodeTagsClient(message) });
  }, []);

  //--- AUTOSCROLL ---
  const [messagesContainer, setMessagesContainer] = React.useState<HTMLDivElement>();
  const shouldScrollRef = React.useRef(true);

  const handleScroll = React.useCallback(() => {
    if (messagesContainer) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainer;
      const atBottom = scrollHeight - scrollTop === clientHeight;
      shouldScrollRef.current = atBottom;
    }
  }, [messagesContainer]);

  React.useEffect(() => {
    dispatch(fetchCampaign());
  }, []);

  React.useEffect(() => {
    if (messages?.data && members.length > 0 && !messages.loading && messagesContainer && shouldScrollRef.current) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [members, messages?.data, messages?.loading, messagesContainer]);

  //!
  React.useEffect(() => {
    const element = document.querySelector('.chat-section') as HTMLDivElement;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      setMessagesContainer(element);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  //# --------------------------------------------------------------------------
  const contextValue = React.useMemo(() => {
    const listMessages: any[] =
      messages?.data
        //@ts-ignore
        .filter((message) => message.syncState !== 'error')
        .map((message) => {
          //console.log('SocialHubChat[message]', message);
          const creator = members?.find((m) => m.userId === message.creatorId);

          return {
            messageId: message.messageId,
            creator: {
              avatarCustomUrl: creator?.avatarUrl,
              ...creator,
            },
            mine: message.creatorId === currentUser?.userId,
            isMod: creator?.roles.some((role) => role.includes('moderator') || role.includes('admin')),
            dataType: message.dataType,
            //@ts-ignore
            text: encodeTagsClient(message.data?.text ?? ''),
            createdAtFull: message.createdAt,
            createdAt: formatDuration(message.createdAt),
            metadata: message.metadata,
            isReply: message.parentId ? true : false,
            handleMessageReply: () =>
              //@ts-ignore
              handleMessageReply(message.messageId, creator?.displayName ?? '', message.data?.text ?? ''),
          };
        })
        .reverse() ?? [];

    if (
      currentUser.isConnected &&
      listMessages.length &&
      campaign?.campaign_id &&
      campaign?.campaign_status === 'active'
    ) {
      const message = {
        createdAt: formatDuration(campaign.campaign_start_date),
        createdAtFull: campaign.campaign_start_date,
        creatorId: 'airdrop_system',
        dataType: 'custom',
        isMod: false,
        memberAvatarUrl: undefined,
        memberDisplayName: 'Airdrop',
        messageId: campaign.campaign_id,
        mine: false,
        text: '',
        metadata: {
          type: 'airdrop_widget',
          id: campaign?.campaign_id,
        },
      };

      listMessages.push(message);
      listMessages.sort((a, b) => {
        return new Date(a.createdAtFull).getTime() - new Date(b.createdAtFull).getTime();
      });
    }

    return {
      isConnected: currentUser.isConnected,
      isGuest: currentUser.isGuest,
      isMuted: members?.find((member) => member.userId === currentUser?.userId)?.isMuted ?? false,
      canMessage: currentUser.capabilities.includes(SocialCapability.CHAT_CAN_MESSAGE),
      messagesLoading: messages?.loading ?? false,
      membersLoading: members.length === 0,
      handleScroll,
      isScrollAtBottom: shouldScrollRef.current,
      sendMessage,
      messages: listMessages,
      ratelimitTimer,
      replyingTo,
      handleMessageInputCancel: () => {
        setReplyingTo(null);
      },
    };
  }, [
    currentUser.isConnected,
    currentUser.isGuest,
    currentUser.userId,
    currentUser.capabilities,
    ratelimitTimer,
    members,
    messages?.loading,
    messages?.data,
    handleScroll,
    sendMessage,
    campaign,
    replyingTo,
    handleMessageReply,
  ]);
  //console.log('SocialHubChat[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Chat;
