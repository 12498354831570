import { appConstants } from '../constants';

export const appInitialize = () => ({
  type: appConstants.INITIALIZE
});

export const appInitialized = () => ({
  type: appConstants.INITIALIZED
});

export const subscribe = () => ({
  type: appConstants.SUBSCRIBE
});

export const subscribed = () => ({
  type: appConstants.SUBSCRIBED
});

export const unsubscribe = () => ({
  type: appConstants.UNSUBSCRIBE
});

export const unsubscribed = () => ({
  type: appConstants.UNSUBSCRIBED
});
