import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { subscribe, unsubscribe } from '@/modules/live-casino/store/actions/app';
import PragmaticLive from '@/store/sagas/pragmaticLiveWS';

//import './index.scss';

type LiveStatsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const localToRemoteId: { [id: string]: string } = {
  '2022': '301',
  '2021': '302',
  '2020': '303',
  '2019': '304',
  '2018': '305',
  '2017': '511',
  '2016': '512',
  '2015': '513',
  '2014': '514',
  '2013': '515',
  '2012': '518',
  '2011': '516',
  '2010': '517',
  '2009': '519',
  '2008': '520',
  '2007': '521',
  '2006': '522',
  '2005': '523',
  '2004': '524',
  '2003': '525',
  '2002': '526',
  '2001': '527',
  '2000': '528',
  '1999': '529',
  '1998': '530',
  '1997': '539',
  '1996': '538',
  '1995': '537',
  '1994': '536',
  '1993': '535',
  '1992': '540',
  '1991': '541',
  '1990': '542',
  '1989': '543',
  '1988': '544',
  '1987': '562',
  '1986': '563',
  '1985': '564',
  '1984': '565',
  '1983': '555',
  '1982': '556',
  '1981': '557',
  '1980': '558',
  '1979': '559',
  '1978': '560',
  '1977': '561',
  '1976': '551',
  '1975': '552',
  '1974': '553',
  '1973': '554',
  '1972': '569',
  '1971': '570',
  '1970': '369',
  '1969': '776',
  '1968': '777',
  '1967': '778',
  '1966': '767',
  '1965': '768',
  '1964': '769',
  '1963': '771',
  '1962': '787',
  '1961': '789',
  '1960': '790',
  '1959': '791',
  '1958': '355',
  '1957': '352',
  '1956': '351',
  '1955': '350',
  '1954': '372',
  '1953': '373',
  '1952': '374',
  '1951': '375',
  '1950': '376',
  '1949': '204',
  '1948': '266',
  '1947': '225',
  '1945': '205',
  '1944': '203',
  '1943': '545',
  '1942': '229',
  '1941': '233',
  '1940': '206',
  '1939': '227',
  '1938': '201',
  '1937': '230',
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const colors: { [key: string]: string } = {
  // generate all roulette colors by number
  '0': 'green',
  '1': 'red',
  '2': 'black',
  '3': 'red',
  '4': 'black',
  '5': 'red',
  '6': 'black',
  '7': 'red',
  '8': 'black',
  '9': 'red',
  '10': 'black',
  '11': 'black',
  '12': 'red',
  '13': 'black',
  '14': 'red',
  '15': 'black',
  '16': 'red',
  '17': 'black',
  '18': 'red',
  '19': 'red',
  '20': 'black',
  '21': 'red',
  '22': 'black',
  '23': 'red',
  '24': 'black',
  '25': 'red',
  '26': 'black',
  '27': 'red',
  '28': 'black',
  '29': 'black',
  '30': 'red',
  '31': 'black',
  '32': 'red',
  '33': 'black',
  '34': 'red',
  '35': 'black',
  '36': 'red',
};

const buildStats = (stats: any, providerId: string, currency: string) => {
  if (providerId === '7') {
    return {
      seatedPlayers: stats?.totalSeatedPlayers,
      maxPlayers: stats?.tableLimits?.maxPlayers ?? 0,
      tableType: stats?.tableType ?? '',
      dealer: stats?.dealer?.name ?? '',
      currency: stats?.currency,
      minBet: stats?.tableLimits?.minBet,
      maxBet: stats?.tableLimits?.maxBet,
      lastRouletteResults:
        stats?.tableType === 'ROULETTE' && stats?.last20Results
          ? stats?.last20Results.map((e: any) => ({ result: e.result, color: e.color }))
          : [],
      image: stats?.tableImage ?? '',
    };
  } else if (providerId === '8') {
    return {
      seatedPlayers: stats?.players,
      maxPlayers: stats?.tableLimits?.maxPlayers ?? 0,
      tableType: stats?.gameType?.toUpperCase() ?? '',
      dealer: stats?.dealer?.name ?? '',
      currency: currency,
      minBet: stats?.betLimits?.[currency.toUpperCase()]?.min,
      maxBet: stats?.betLimits?.[currency.toUpperCase()]?.max,
      lastRouletteResults:
        stats?.gameType === 'Roulette' && stats?.results
          ? stats?.results.map((e: any) => ({ result: e, color: colors[e] }))
          : [],
      image: stats?.videoSnapshot?.thumbnails?.M ?? '',
    };
  }
};

const LiveStats = (componentProps: LiveStatsProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const provider_id = dataElementContext?.provider_id;
  const id = dataElementContext?.id;
  const dispatch = useAppDispatch();
  const tables = useAppSelector((state) => state.pragmaticLive.tables);
  const evoTables: any = useAppSelector((state) => state.liveCasino.tables?.state?.tables);

  React.useEffect(() => {
    if (provider_id === '7' && id && localToRemoteId[id]) {
      PragmaticLive.subscribe(localToRemoteId[id], 'RON');
    }

    if (provider_id === '8' && id) {
      dispatch(subscribe());
    }

    return () => {
      if (provider_id === '7' && id && localToRemoteId[id]) {
        PragmaticLive.unsubscribe(localToRemoteId[id]);
      }
      if (provider_id === '8' && id) {
        dispatch(unsubscribe());
      }
    };
  }, [provider_id, id]);

  const contextValue = React.useMemo(() => {
    let stats: any = {};
    if (provider_id === '7' && id && localToRemoteId[id] && tables[localToRemoteId[id]]) {
      stats = buildStats(tables[localToRemoteId[id]], provider_id, window.config.defaultCurrency);
    } else if (provider_id === '8' && id) {
      const [gameType, tableId] = id.split('/');
      if (evoTables?.[tableId]) {
        stats = buildStats(evoTables?.[tableId], provider_id, window.config.defaultCurrency);
      }
    }

    /*
      tableType: "ROULETTE"
      tableType: "BACCARAT"
      tableType: "BLACKJACK"
    */

    return {
      ...dataElementContext,
      stats: stats,
    };
  }, [dataElementContext, componentProps, provider_id, id, tables, evoTables]);

  //console.log('contextValue', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LiveStats;
