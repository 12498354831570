import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import DefaultAvatar from '../../../_common/assets/img-avatar-male.png';
import './index.scss';
import {
  PostRepository,
  PostContentType,
  CommentRepository,
  FileRepository,
  ReactionRepository,
  subscribeTopic,
  getPostTopic,
  SubscriptionLevels,
} from '@amityco/ts-sdk';

import { SocialCapability, SocialHubUser } from '../..';
import LOG from '../../utils/Log';
import { encodeTagsClient, formatDuration } from '../../utils/functions';

// ---------------------------------------------- TYPES ----------------------------------------------

enum PostType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  POLL = 'poll',
  TICKET = 'sb_ticket.shared', // Custom post type
}

type ImagePostData = {
  imageUrl: string;
};

type VideoPostData = {
  videoUrl: string;
  videoThumbnailUrl: string;
};

type PollPostData = {
  pollId: string;
};

type TicketPostData = {
  type: string;
  ticket_number: string;
  // ...
};

type Post = {
  postId: string;
  text: string;
  author?: SocialHubUser;
  createdAt: string;
  lastEditedAt?: string;
  likes: number;
  shares: number;
  isLikedByMe: boolean;
  comments: PostComment[];
  commentsCount: number;
  metadata: any;
  data?: any;
} & (
  | { type: PostType.TEXT }
  | ({ type: PostType.IMAGE } & ImagePostData)
  | ({ type: PostType.VIDEO } & VideoPostData)
  | ({ type: PostType.POLL } & PollPostData)
  | ({ type: PostType.TICKET } & TicketPostData)
);

type PostComment = {
  commentId: string;
  text: string;
  createdAt: string;
  creator: Amity.User;
  mine: boolean;
  reactionCount: number;
  myReactions: string[];
  parentId: string;
  isReply: boolean;
  isEdited: boolean;
  replyCount: number;
  replies: PostComment[];
  handleLoadCommentReplies: (commentId: string) => void;
  // hasMoreReplies: boolean;
  // nextPageFn: () => void | undefined;
};

// ---------------------------------------------- FUNCTIONS ----------------------------------------------
const likePost = (postId: string, onSuccess?: () => void) => {
  ReactionRepository.addReaction('post', postId, 'like').then((success) => {
    LOG.ok('POST LIKED', success);
    onSuccess?.();
  });
};

const unlikePost = (postId: string, onSuccess?: () => void) => {
  ReactionRepository.removeReaction('post', postId, 'like')
    .then((success) => {
      LOG.ok('POST UNLIKED', success);
      onSuccess?.();
    })
    .catch((error) => {
      LOG.error('Error unliking post', error);
    });
};

const sharePost = (postId: string, text: string) => {
  try {
    const baseUrl = window.location.protocol + '//' + window.location.host + '/#postId=' + postId;

    if (navigator.share) {
      navigator
        .share({
          // title: 'title',
          // text,
          url: baseUrl,
        })
        .then(() => {
          ReactionRepository.addReaction('post', postId, '_shared').then((success) => {
            LOG.ok('POST SHARED', success, postId);
          });
        })
        .catch((error) => {
          LOG.error('Error sharing', error);
        });
    }
  } catch (error) {
    LOG.error('Error sharing', error);
  }
};

const likeComment = (commentId: string) => {
  ReactionRepository.addReaction('comment', commentId, 'like').then((success) => {
    LOG.ok('COMMENT LIKED', success);
  });
};

const unlikeComment = (commentId: string) => {
  ReactionRepository.removeReaction('comment', commentId, 'like')
    .then((success) => {
      LOG.ok('COMMENT UNLIKED', success);
    })
    .catch((error) => {
      LOG.error('Error unliking comment', error);
    });
};

const createComment = (postId: string, text: string, parentCommentId?: string, onSuccess?: () => void) => {
  const comment = {
    data: { text },
    referenceId: postId,
    referenceType: 'post' as Amity.CommentReferenceType,
    parentId: parentCommentId,
  };

  CommentRepository.createComment(comment).then((newComment) => {
    LOG.ok('SENT COMMENT', newComment);
    onSuccess?.();
  });
};

const updateComment = (commentId: string, text: string, onSuccess?: () => void) => {
  const updatedComment = {
    data: { text },
    referenceType: 'post' as Amity.CommentReferenceType,
  };

  CommentRepository.updateComment(commentId, updatedComment).then((newComment) => {
    LOG.ok('UPDATED COMMENT', newComment);
    onSuccess?.();
  });
};

// --------------------------------------------------------------------------------------

type PostProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Post = (componentProps: PostProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  const {
    postId,
    showComments,
    currentUser,
    selectedTab,
  }: {
    postId: string;
    showComments: boolean;
    currentUser: SocialHubUser;
    selectedTab: string;
  } = dataElementContext;

  const canCommentOnFeed =
    dataElementContext.canCommentOnFeed ||
    (currentUser?.capabilities.includes(SocialCapability.FEED_CAN_COMMENT) ?? false);

  // ---------------------------------------------- STATE ----------------------------------------------

  const [post, setPost] = React.useState<Post>();
  const [postLoading, setPostLoading] = React.useState(true);
  const [replyingTo, setReplyingTo] = React.useState<{ commentId: string; name: string; message: string } | null>(null);
  const [editingComment, setEditingComment] = React.useState<{ commentId: string; message: string } | null>(null);
  const [postCommentsHasNextPage, setPostCommentsHasNextPage] = React.useState(false);
  const nextPageFn = React.useRef<() => void | undefined>();

  const [postComments, setPostComments] = React.useState<PostComment[]>([]);
  const repliesNextPageFn = React.useRef<Record<string, (() => void) | undefined>>({});

  // ---------------------------------------------- HOOKS ----------------------------------------------

  //# POST LIKE/UNLIKE
  // const onPostLike = React.useCallback((postId: string, isLikedByMe: boolean) => {
  //   if (!postId) return;
  //   if (!isLikedByMe) likePost(postId);
  //   else unlikePost(postId);
  // }, []);

  const onPostLike = React.useCallback((postId: string, isLikedByMe: boolean) => {
    if (!postId) return;
    if (!isLikedByMe) likePost(postId, () => setPost((prev) => ({ ...prev, isLikedByMe: true }) as Post));
    else unlikePost(postId, () => setPost((prev) => ({ ...prev, isLikedByMe: false }) as Post));
  }, []);

  // const onPostLike = React.useCallback(() => {
  //   if (!postId) return;

  //   LOG(post?.data?.myReactions);
  //   LOG('likes', post?.likes);

  //   if (post?.isLikedByMe) {
  //     ReactionRepository.removeReaction('post', postId, 'like')
  //       .then((success) => {
  //         LOG.ok('POST UNLIKED', success);
  //         setPost((prev) => ({ ...prev, likes: prev?.likes ? prev.likes - 1 : 1, isLikedByMe: false }) as Post);
  //       })
  //       .catch((error) => {
  //         LOG.error('Error unliking post', error);
  //       })
  //       .finally(() => {
  //         // post.data.myReactions = post.data.myReactions.filter((r: string) => r !== 'like');
  //         LOG(post.data?.myReactions);
  //       });
  //   } else {
  //     ReactionRepository.addReaction('post', postId, 'like')
  //       .then((success) => {
  //         LOG.ok('POST LIKED', success);
  //         setPost((prev) => ({ ...prev, likes: prev?.likes ? prev.likes + 1 : 1, isLikedByMe: true }) as Post);
  //       })
  //       .finally(() => {
  //         // if (!post.data.myReactions.includes('like')) post.data.myReactions = [...post.data.myReactions, 'like'];
  //         LOG(post?.data?.myReactions);
  //       });
  //   }
  // }, [post?.data?.myReactions, post?.isLikedByMe, post?.likes, postId]);

  //# POST SHARE
  const onPostShare = React.useCallback(() => {
    if (!postId) return;
    sharePost(postId, post?.text ?? '');
  }, [post?.text, postId]);

  //# COMMENT SEND
  const onCommentSend = React.useCallback(
    (commentText: string) => {
      if (!canCommentOnFeed) return;
      if (editingComment?.commentId) {
        setEditingComment(null);
        updateComment(editingComment.commentId, commentText);
      } else {
        if (replyingTo?.commentId) setReplyingTo(null);

        createComment(postId, commentText, replyingTo?.commentId, () => {
          // Scroll to the new comment if it's not a reply
          if (!replyingTo?.commentId) setShouldScroll(true);
        });
      }
    },
    [canCommentOnFeed, editingComment?.commentId, postId, replyingTo?.commentId],
  );

  //# COMMENT LIKE/UNLIKE
  const onCommentLike = React.useCallback((commentId: string, myReactions: string[]) => {
    if (!commentId) return;
    if (!myReactions.length) likeComment(commentId);
    else unlikeComment(commentId);
  }, []);

  //# COMMENT REPLY
  const handleCommentReply = React.useCallback(
    (commentId: string, name: string, message: string) => {
      if (!canCommentOnFeed) return;
      if (!commentId) return;
      setEditingComment(null);
      setReplyingTo({ commentId, name, message: encodeTagsClient(message) });
    },
    [canCommentOnFeed],
  );

  //# COMMENT EDIT
  const handleCommentEdit = React.useCallback((commentId: string, message: string) => {
    if (!commentId) return;
    setReplyingTo(null);
    setEditingComment({ commentId, message: encodeTagsClient(message) });
  }, []);

  const parseComment = React.useCallback(
    (comment: Amity.Comment<'text'>, handleLoadCommentReplies: (commentId: string) => void) => {
      return {
        dataType: comment.dataType,
        metadata: comment.metadata,
        commentId: comment.commentId,
        text: encodeTagsClient(comment.data?.text ?? ''),
        createdAt: formatDuration(comment.createdAt),
        creator: {
          ...comment.creator,
          isMod: comment.creator?.roles.some((role) => role.includes('moderator') || role.includes('admin')),
        },
        mine: comment.creator?.userId === currentUser?.userId,
        reactionCount: comment.reactionsCount,
        myReactions: comment.myReactions ?? [],
        parentId: comment.parentId,
        isReply: comment.parentId ? true : false,
        isEdited: comment.editedAt !== comment.createdAt,
        //TODO: Amity bug, childrenNumber is updated wrongly after fetching children comments
        replyCount: comment.childrenNumber,
        currentUser, // workaround for templatization issue
        onCommentLike: () => onCommentLike(comment.commentId, comment.myReactions ?? []),
        handleCommentReply: () =>
          handleCommentReply(comment.commentId, comment.creator?.displayName ?? '', comment.data?.text ?? ''),
        handleCommentEdit: () => handleCommentEdit(comment.commentId, comment.data?.text ?? ''),
        handleLoadCommentReplies,

        replies:
          //@ts-ignore Amity childrenComment is missing why
          comment.childrenComment?.map((reply: Amity.Comment<'text'>) =>
            parseComment(reply, () => handleLoadCommentReplies(reply.commentId)),
          ) ?? [],
      } as PostComment;
    },
    [currentUser, handleCommentEdit, handleCommentReply, onCommentLike],
  );
  //# Load comment replies
  const handleLoadCommentReplies = React.useCallback(
    (commentId: string) => {
      // Load the comment replies next page
      if (repliesNextPageFn.current[commentId]) {
        repliesNextPageFn.current[commentId]?.();
        return;
      }

      const textOnlyParam: Amity.CommentLiveCollection = {
        referenceType: 'post',
        referenceId: postId,
        limit: 10,
        sortBy: 'lastCreated',
        parentId: commentId,
        dataTypes: {
          values: ['text'],
          matchType: 'exact',
        },
      };

      CommentRepository.getComments(textOnlyParam, (comments) => {
        comments.loading ? LOG('LOADING REPLIES...') : LOG.ok('📣✅ REPLIES SYNCED', comments);
        if (comments.loading) return;

        repliesNextPageFn.current[commentId] = comments.onNextPage;

        if (comments.data) {
          // Parse comment list
          const parsedComments: PostComment[] = (comments as Amity.LiveCollection<Amity.Comment<'text'>>).data.map(
            (comment) => ({
              ...parseComment(comment, () => handleLoadCommentReplies(comment.commentId)),
              //@ts-ignore Amity childrenComment is missing why
              // replies: comment.childrenComment.map((comment: Amity.Comment<'text'>) =>
              //   parseComment(comment, () => handleLoadCommentReplies(comment.commentId)),
              // ),
            }),
          );

          // }
          // .reverse();

          setPostComments((comments) =>
            comments.map((comment) =>
              comment.commentId === commentId ? { ...comment, replies: parsedComments } : comment,
            ),
          );
        }
      });
    },
    [parseComment, postId],
  );

  //# Load post data from Amity
  React.useEffect(() => {
    if (!postId) return;

    let unsubPostContent: Amity.Unsubscriber;

    if (showComments) LOG('🔴 SUBSCRIBING TO POST...');

    // Load the main post data
    const unsubPost = PostRepository.getPost(postId, (post) => {
      post.loading ? LOG('POST LOADING...') : LOG.ok('POST LOADED', post);
      setPostLoading(post.loading);
      if (post.loading) return;

      // Assemble our own post object from the Amity post data
      setPost({
        postId,
        type: PostType.TEXT,
        text: encodeTagsClient(post.data.data.text),
        author: post.data.creator,
        createdAt: formatDuration(post.data.createdAt, true),
        lastEditedAt: post.data.editedAt ? formatDuration(post.data.editedAt) : undefined,
        likes: post.data.reactions.like ?? 0,
        shares: post.data.reactions._shared ?? 0,
        isLikedByMe: post.data.myReactions?.includes('like') ?? false,
        metadata: post.data.metadata,
        comments: [],
        commentsCount: post.data.commentsCount,
      });

      // Custom post type check
      if (post.data.dataType === PostType.TICKET) {
        setPost((prev) => ({ ...prev, type: PostType.TICKET, data: post.data.data }) as Post);
      }

      // Load the Amity post child data (if it exists) and fill in the extra data (image, video, etc)
      if (post.data?.children.length) {
        const childPostId = post.data.children[0];

        const unsubChild = PostRepository.getPost(childPostId, (childPost) => {
          post.loading ? LOG('CHILD POST LOADING...') : LOG.ok('CHILD POST LOADED', childPost);
          if (childPost.loading) return;

          const childPostType = childPost.data.dataType;
          const childPostData = childPost.data.data;
          let postData = {};

          switch (childPostType) {
            case PostContentType.IMAGE: {
              const fileId = childPostData.fileId;
              FileRepository.getFile<'image'>(fileId).then((file) => {
                postData = {
                  type: PostType.IMAGE,
                  imageUrl: `${file.data.fileUrl}?size=medium`,
                };
                setPost((prev) => ({ ...prev, ...postData }) as Post);
              });
              break;
            }
            case PostContentType.VIDEO: {
              const fileId = childPostData.videoFileId.original;
              const videoThumbnailUrl = FileRepository.fileUrlWithSize(childPostData.thumbnailFileId, 'medium');
              FileRepository.getFile<'video'>(fileId).then((file) => {
                FileRepository.getFile<'image'>(videoThumbnailUrl).then((thumb) => {
                  //! something here!!
                  postData = {
                    type: PostType.VIDEO,
                    // @ts-ignore amity incomplete types
                    videoUrl: `${file.data.videoUrl['720p']}?size=medium`,
                    videoThumbnailUrl: thumb.data.fileUrl,
                  };
                  LOG(postData);
                  setPost((prev) => ({ ...prev, ...postData }) as Post);
                });
              });
              break;
            }
            case PostContentType.POLL: {
              postData = {
                type: PostType.POLL,
                pollId: childPost.data.data.pollId,
              };
              setPost((prev) => ({ ...prev, ...postData }) as Post);
              break;
            }
            default:
              break;
          }
        });
        unsubChild();
      }

      // If this is a full post view (decided via showComments flag), load the comments too
      if (showComments) {
        //# FETCH POST COMMENTS
        const textOnlyParam: Amity.CommentLiveCollection = {
          referenceType: 'post',
          referenceId: postId,
          limit: 10,
          sortBy: 'lastCreated',
          dataTypes: {
            values: ['text'],
            matchType: 'exact',
          },
        };

        CommentRepository.getComments(textOnlyParam, (comments) => {
          comments.loading ? LOG('LOADING COMMENTS...') : LOG.ok('📣✅ COMMENTS SYNCED', comments);
          if (comments.loading) return;

          setPostCommentsHasNextPage(comments.hasNextPage ?? false);
          nextPageFn.current = comments.onNextPage;

          if (comments.data) {
            // Parse comment list
            const parsedComments: PostComment[] = (comments as Amity.LiveCollection<Amity.Comment<'text'>>).data.map(
              (comment) => parseComment(comment, () => handleLoadCommentReplies(comment.commentId)),
            );
            setPostComments(parsedComments);
          }
        });

        // Subscribe to post realtime comments (amity)
        LOG('🔴 SUBSCRIBING TO POST TOPIC...');
        unsubPostContent = subscribeTopic(getPostTopic(post.data, SubscriptionLevels.COMMENT));
      }
    });

    if (!showComments) unsubPost();

    return () => {
      // Cleanup any realtime post update subscriptions that we created
      if (showComments) {
        LOG('⭕ UNSUBSCRIBING FROM POST...');
        unsubPost();
        LOG('⭕ UNSUBSCRIBING FROM POST TOPIC...');
        unsubPostContent?.();
      }
    };
  }, [handleLoadCommentReplies, parseComment, postId, showComments, selectedTab]);

  //# Autoscroll on new comment sent
  const [shouldScroll, setShouldScroll] = React.useState(false);
  React.useEffect(() => {
    if (shouldScroll && post?.comments.length) {
      document.querySelector('.wl-social-hub-feed-post')?.scrollTo({ top: 300, behavior: 'smooth' });
      setShouldScroll(false);
    }
  }, [post?.comments.length, shouldScroll]);

  //# Comment lazy loading
  const lastCommentRef = React.useRef<HTMLElement>();
  React.useEffect(() => {
    const els = document.querySelectorAll('.wl-social-hub-message');
    if (!els.length) return;
    lastCommentRef.current = els[els.length - 1] as HTMLElement;

    const options = {
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && postCommentsHasNextPage) {
        LOG.info('LAZY LOADING MORE COMMENTS...', entry);
        nextPageFn.current?.();
      }
    }, options);

    if (lastCommentRef.current) {
      observer.observe(lastCommentRef.current);
    }

    return () => {
      if (lastCommentRef.current) {
        observer.unobserve(lastCommentRef.current);
      }
    };
  }, [nextPageFn, postCommentsHasNextPage]);

  //! VIDEO CONTAINER
  const [mediaContainer, setMediaContainer] = React.useState<HTMLElement>();
  React.useEffect(() => {
    if (post?.type !== PostType.VIDEO) return;
    const container = document.querySelector(`.post-video-${postId}`) as HTMLDivElement;
    if (!container) return;
    setMediaContainer(container);
  }, [post?.type, postId]);

  // ---------------------------------------------- CONTEXT ----------------------------------------------

  const contextValue = React.useMemo(() => {
    return {
      ...post,

      postLoading,
      showComments,
      onPostLike: () => onPostLike(postId, post?.isLikedByMe ?? false),
      onPostComment: () => dataElementContext.parentContext.setSelectedPostId(postId),
      onPostShare,
      onCommentSend,
      replyingTo,
      editingComment,
      handleCommentInputCancel: () => {
        setReplyingTo(null);
        setEditingComment(null);
      },
      canCommentOnFeed,

      // LEGACY PROPS (mostly aliases to stuff from the post object above. only here because they're being used in templates, can change/rename if we refactor the templates to only use the values in "post")
      postText: post?.text,
      postType: post?.type,
      reactionsCount: post?.likes,
      sharesCount: post?.shares,
      myReactions: post?.isLikedByMe,
      commentsCount: post?.commentsCount,
      creator: {
        avatarCustomUrl: window.config.socialDefaultSystemUserAvatar || DefaultAvatar,
        ...post?.author,
      },
      comments: postComments,
    };
  }, [
    post,
    postLoading,
    showComments,
    onPostLike,
    onPostShare,
    onCommentSend,
    replyingTo,
    editingComment,
    canCommentOnFeed,
    postComments,
    dataElementContext.parentContext,
    postId,
  ]);
  //console.log('SocialHubPost[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
      {mediaContainer &&
        createPortal(
          <video
            key={postId}
            poster={(post as VideoPostData).videoThumbnailUrl}
            src={(post as VideoPostData).videoUrl}
            style={{ width: '100%' }}
            onLoadedMetadata={(e) => {
              const v = e.target as HTMLVideoElement;
              const aspectRatio = v.videoWidth / v.videoHeight;
              const height = v.offsetWidth / aspectRatio;
              v.style.height = `${height}px`;
            }}
            controls
          >
            <track kind="captions" />
          </video>,
          mediaContainer,
        )}
    </ModuleElementDiv>
  );
};

export default Post;
