import React from 'react';
import styled from 'styled-components';
import { DataElementContext } from '../../../../../../page-components/common/DataElementContext';
import { PollRepository } from '@amityco/ts-sdk';

import './index.scss';
import LOG from '../../../utils/Log';

type PollProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Poll = (componentProps: PollProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  const { pollId } = dataElementContext;

  const [poll, setPoll] = React.useState<Amity.Snapshot<Amity.Poll>>();
  const [selectedAnswerIds, setSelectedAnswerIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!pollId) return;

    PollRepository.observePoll(pollId, setPoll);
  }, [pollId]);

  const onAnswerSelect = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.answerid != null && e.currentTarget.dataset.answerid !== '') {
        if (poll?.data?.isVoted || (poll?.data?.closedAt && Date.parse(poll?.data?.closedAt) <= Date.now())) return;

        const answerId = e.currentTarget.dataset.answerid;

        setSelectedAnswerIds((selectedAnswers) => {
          if (selectedAnswers.includes(answerId)) return selectedAnswers.filter((id) => id !== answerId);
          else return poll?.data?.answerType === 'single' ? [answerId] : [...selectedAnswers, answerId];
        });
        LOG.info('SELECTED ANSWER', answerId);
      }
    },
    [poll?.data?.answerType, poll?.data?.closedAt, poll?.data?.isVoted],
  );

  const onVoteSubmit = React.useCallback(() => {
    if (!selectedAnswerIds.length) return;

    PollRepository.votePoll(pollId, selectedAnswerIds).then((poll) => {
      LOG.info('VOTED POLL', poll);
    });
  }, [pollId, selectedAnswerIds]);

  const contextValue = React.useMemo(() => {
    const isVoted = poll?.data?.isVoted || (poll?.data?.closedAt && Date.parse(poll?.data?.closedAt) <= Date.now());
    const totalVotes = poll?.data?.answers.reduce((total, answer) => total + answer.voteCount, 0);

    return {
      question: poll?.data?.question,
      choiceType: poll?.data?.answerType,
      isVoted,
      totalVotes,
      onAnswerSelect,
      onVoteSubmit,
      selectedAnswerIds,
      answers:
        poll?.data?.answers.map((answer) => ({
          id: answer.id,
          text: answer.data,
          voteCount: answer.voteCount,
          isVotedByUser: answer.isVotedByUser,
          isSelected: selectedAnswerIds.includes(answer.id),
          isVoted, // repeated for templatization
          totalVotes, // repeated for templatization
        })) ?? [],
    };
  }, [onAnswerSelect, onVoteSubmit, poll?.data, selectedAnswerIds]);
  // console.log('SocialHubPoll[contextValue]', contextValue);

  return (
    <>
      <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
        <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
      </ModuleElementDiv>
    </>
  );
};

export default Poll;
