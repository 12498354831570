import { PaymentProviderInterface } from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import PaymentProvider, {
  PaymentProviderConstructor,
} from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import { PaymentProvider as PaymentProviderConstant } from '@/constants/paymentProvider';
import { AmountButtonInterface } from '@/components/classes/PaymentProviders/Interfaces/AmountButtonInterface';
import { oktoGetCustomer, oktoReset, oktoCreatePaymentCode } from '@/modules/casino/store/actions/deposit';
import cordovaRedirect from '../../utils/cordovaRedirect';

class Okto extends PaymentProvider implements PaymentProviderInterface {
  constructor(data: PaymentProviderConstructor) {
    super(data);
    this.setType(PaymentProviderConstant.okto);
  }
  init(data: any): void {
    super.init(data);
    if (data.getCustomer) {
      this.dispatch(oktoGetCustomer()); // this is needed to know if we need to show T&C to user
    }
  }

  destroy() {
    this.dispatch(oktoReset());
  }

  confirmPayment(): void {
    this.dispatch(oktoCreatePaymentCode(this.paymentDetails?.amount ?? 0, this.paymentDetails?.bonusId ?? null));
  }

  redirect(url: string): void {
    if (window.config.cordova) {
      this.openedPopupUrl = cordovaRedirect(url, this.openedPopupUrl);
      return;
    }
    window.location.href = url;
  }

  getAmountButtons(
    state: any,
    opt: {
      currentCurrency: string;
      setStateCallback: (state: any) => any;
      onDepositButtonClick: (e: any) => any;
      ignoreBonus?: boolean;
      grid?: {
        default: number;
        fallback: number;
        fallbackRemainder: number[];
      };
    },
  ): AmountButtonInterface[] {
    opt.grid = {
      default: 4,
      fallback: 3,
      fallbackRemainder: [1],
    };
    opt.ignoreBonus = true;
    return super.getAmountButtons(state, opt);
  }
}

export default Okto;
