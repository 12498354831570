import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';
import { uniqueId } from 'lodash-es';

import { useAppSelector, useAppDispatch } from '../../../store';
import { fetchSources } from '../../../store/slices/dataSources';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import Providers from '../../../common/providers';

import './index.scss';

type FilterOption = {
  id: number;
  name: string;
  label: string;
};

type SortOption = {
  id: string;
  label: string;
  icon: string;
};

type DataSourceToolsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type Item = {
  id: string;
  name: string;
  provider_id: string;
};

type Items = Item[];

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const DataSourceToolsDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const DataSourceTools = (componentProps: DataSourceToolsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  //console.log('props', props);

  const matches = useMatches();
  const dispatch = useAppDispatch();
  const [__id] = React.useState(uniqueId('unique-'));

  // try to initialize sourceId from the properties object
  let sourceId = props.properties?.targetId;

  if (props.properties && props.properties.targetIdFromPath && props.properties.pathParamKey) {
    if (matches && matches.length) {
      // there is no sourceId set as props; maybe were in a route that includes a :gameId param
      const match = matches[0];
      let pathParamKey = props.properties.pathParamKey;
      if (pathParamKey != null && pathParamKey[0] === ':') {
        pathParamKey = pathParamKey.slice(1);
      }
      if (match.params && match.params[pathParamKey] != null) {
        sourceId = match.params[pathParamKey];
        if (props?.properties?.pathParamKeyPrefix) {
          sourceId = `${props?.properties?.pathParamKeyPrefix}${sourceId}`;
        }
      }
    }
  }

  const sources = useAppSelector((state) => state.dataSources.items);
  const loading = useAppSelector((state) => state.dataSources.loading);
  const error = useAppSelector((state) => state.dataSources.error);
  const accessToken = useAppSelector((state) => state.authentication.access_token);

  React.useEffect(() => {
    if (accessToken != null) dispatch(fetchSources({ ids: [sourceId] }));
  }, [accessToken, matches]);

  const itemPerPage =
    props.properties.itemsPerPage && !isNaN(Number(props.properties.itemsPerPage))
      ? Number(props.properties.itemsPerPage)
      : 15;

  const [state, setState] = React.useState({
    showMore: !!props.properties.showMore,
    showSearch: !!props.properties.showSearch,
    showSort: !!props.properties.showSort,
    showFilter: !!props.properties.showFilter,
    multiFilter: !!props.properties.multiFilter,
    itemsPerPage: itemPerPage,
    totalShowing: itemPerPage,
    query: '',
    filterField: '',
    filterValue: '',
    filterValues: [],
    sortField: '',
    queryHasFocus: false,
    expandQueryField: false,
  });

  const filteredItems = React.useMemo<Items>(() => {
    const filteredItems = sources[sourceId]?.data ?? null;

    return filteredItems;
  }, [sources[sourceId], state]);

  const onToggleExpand = (e: React.FormEvent<HTMLInputElement>) => {
    // e.preventDefault();
    // e.stopPropagation();
    if (state.queryHasFocus) return;
    setState((v) => ({ ...v, expandQueryField: !v.expandQueryField }));
  };

  const onQueryChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.target ? (e.target as HTMLInputElement).value : '';
    setState((v) => ({ ...v, query: value ?? '', totalShowing: v.itemsPerPage }));
  };

  const onQueryFocus = () => {
    setState((v) => ({ ...v, queryHasFocus: true }));
  };
  const onQueryBlur = () => {
    setTimeout(() => {
      setState((v) => ({ ...v, queryHasFocus: false }));
    }, 200);
  };

  const onFilterChange = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.filterField && e.currentTarget.dataset.filterValue) {
      if (props.properties.multiFilter) {
        e.stopPropagation();
        e.preventDefault();
      }

      const dataSet = e.currentTarget.dataset;
      setState((v: any) => {
        const filterValues: any[] = [...v.filterValues];
        let filterValue = dataSet.filterValue ?? '';
        let filterField = dataSet.filterField ?? '';
        const filterLabel = dataSet.filterLabel ?? '';

        if (props.properties.multiFilter) {
          let exists = false;
          let index = -1;
          filterValues.find((fv, i) => {
            if (fv.filterField === filterField && fv.filterValue === filterValue) {
              exists = true;
              index = i;
              return true;
            }
            return false;
          });

          if (exists) {
            filterValues.splice(index, 1);

            if (filterValues.length === 0) {
              filterField = '';
              filterValue = '';
            }
          } else {
            filterValues.push({ filterField, filterValue, filterLabel });
          }
        }

        return {
          ...v,
          filterField: filterField ?? '',
          filterValue: filterValue ?? '',
          totalShowing: v.itemsPerPage,
          filterValues,
        };
      });
    }
  };

  const onRemoveFilterValue = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.filterField && e.currentTarget.dataset.filterValue) {
      const dataSet = e.currentTarget.dataset;
      setState((v: any) => {
        const filterValues: any[] = [...v.filterValues];
        const filterValue = dataSet.filterValue ?? '';
        const filterField = dataSet.filterField ?? '';

        let exists = false;
        let index = -1;
        filterValues.find((fv, i) => {
          if (fv.filterField === filterField && fv.filterValue === filterValue) {
            exists = true;
            index = i;
            return true;
          }
          return false;
        });

        if (exists) {
          filterValues.splice(index, 1);
        }

        return {
          ...v,
          totalShowing: v.itemsPerPage,
          filterValues,
        };
      });
    }
  };

  const onSortChange = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset) {
      const dataSet = e.currentTarget.dataset;
      setState((v) => ({
        ...v,
        sortField: dataSet.sortField ?? '',
      }));
    }
  };

  const onShowMore = () => {
    setState((v) => {
      if (v.showMore) {
        const newV = { ...v };
        newV.totalShowing += v.itemsPerPage;
        return newV;
      }
      return v;
    });
  };

  //const onToggle = () => {};

  const existentProvidersIds = React.useMemo(() => {
    const tmp: { [id: number]: boolean } = {};
    filteredItems &&
      filteredItems.forEach((el: any) => {
        if (el.provider_id != null) tmp[el.provider_id] = true;
      });
    return tmp;
  }, [filteredItems]);

  const existentProviders = Providers.filter((p) => {
    return existentProvidersIds[p.id] != null;
  });

  const filterOptions: FilterOption[] = [
    ...existentProviders.map((p) => {
      let selected = false;

      state.filterValues.forEach((fv: any) => {
        if (fv.filterField === 'provider_id' && fv.filterValue.toString() === p.id.toString()) selected = true;
      });

      return {
        ...p,
        name: 'provider_id',
        selected,
      };
    }),
  ];
  const sortOptions: SortOption[] = [
    { id: '', label: 'None', icon: 'bi-x-circle' },
    { id: 'nameAsc', label: 'A-Z', icon: 'bi-sort-alpha-down' },
    { id: 'nameDesc', label: 'Z-A', icon: 'bi-sort-alpha-down-alt' },
  ];

  const contextValue = React.useMemo(() => {
    let items = filteredItems ? [...filteredItems] : [];

    if (state.query) {
      items = items.filter((item) => {
        if (item && item.name) {
          if (item.name.toLowerCase().indexOf(state.query.toLowerCase()) > -1) return true;
        }
        return false;
      });
    }

    if (state.filterField && props.properties?.multiFilter) {
      const selectedProviders: any = {};

      state.filterValues.forEach((fv: any) => {
        if (fv.filterField === state.filterField) selectedProviders[fv.filterValue] = true;
      });

      if (Object.keys(selectedProviders).length !== 0) {
        items = items.filter((item: any) => {
          if (item && item[state.filterField] != null) {
            if (selectedProviders[item[state.filterField]]) return true;
          }
          return false;
        });
      }
    } else if (state.filterField && state.filterValue) {
      items = items.filter((item: any) => {
        if (item && item[state.filterField] != null) {
          if (item[state.filterField].toString() === state.filterValue.toString()) return true;
        }
        return false;
      });
    }

    if (state.sortField) {
      switch (state.sortField) {
        case 'nameAsc':
          items.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          break;
        case 'nameDesc':
          items.sort((a, b) => {
            return b.name.localeCompare(a.name);
          });
          break;
        default:
        /* nothing to do */
      }
    }

    const totalShowing = state.totalShowing > items.length ? items.length : state.totalShowing;

    return {
      ...state,
      onToggleExpand,
      onQueryChange,
      onQueryFocus,
      onQueryBlur,
      onFilterChange,
      onRemoveFilterValue,
      onRemoveAllFilterValues: () => {
        setState((v: any) => ({ ...v, filterValues: [] }));
      },
      onSortChange,
      //onToggle: onToggle,
      onShowMore,
      totalShowing,
      items: state.showMore ? items.slice(0, state.totalShowing) : items,
      total: items.length,
      loading: loading && items.length === 0,
      error: error,
      filterOptions,
      sortOptions,
      __id,
    };
  }, [filteredItems, state, loading, error, matches]);

  if (!sourceId) return null;

  // console.log('DataSourceTools[contextValue]', contextValue);

  return (
    <DataSourceToolsDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </DataSourceToolsDiv>
  );
};

export default DataSourceTools;
