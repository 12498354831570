import React from 'react';
import styled from 'styled-components';
import { extend } from 'lodash-es';

import craftJsParser from '../../components/utils/craftJsParser';
import { useAppSelector } from '../../store';
import { LayoutContext } from '../common/LayoutContext';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {},
  visibility: {},
};

const TabContainerDiv = styled.div((props) => props.$styleText);

export const TabContainer = (props) => {
  const pageDataContext = React.useContext(PageDataContext);
  const context = React.useContext(LayoutContext);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user'),
  );

  let layoutStates = [];
  let selectedLayout = '';

  if (context) ({ selectedLayout, layoutStates } = context);

  const content = [];
  try {
    const data = getProjectDataFromContext(pageDataContext);

    (layoutStates ?? []).forEach((state, i) => {
      if (selectedLayout && state.value === selectedLayout) {
        const item = craftJsParser({
          craftState: data,
          rootNodeId: props.linkedNodes[selectedLayout],
          pageId: props.refType === 'layout' ? null : props.refId,
          pageLayoutId: props.refType === 'layout' ? props.refId : null,
          pageType: props.refType,
          options: {
            authenticated,
          },
        });

        content.push(React.cloneElement(item, { key: i }));
      }
    });
  } catch (err) {
    console.error(err);
    return null;
  }

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  return (
    <TabContainerDiv
      data-is-node="true"
      className={props.className ?? ''}
      $styleText={props.styleText}
      {...extraDataProps}
    >
      {content}
    </TabContainerDiv>
  );
};
