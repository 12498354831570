import React from 'react';
import styled from 'styled-components';
import { OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { useMediaQuery } from 'usehooks-ts';

import './MenuButton.scss';
import LinkedElement from '../../components/linked-element';
import Offcanvas from './lib/Offcanvas';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    backdrop: true,
    fade: true,
    scrollable: false,
    direction: 'start', // start, top, end, bottom
    isOpen: false,
  },
  canDrag: true,
};

const MenuButtonDiv = styled.div((props) => props.$styleText);

export const MenuButton = (componentProps) => {
  let props = componentProps;

  const isDesktop = useMediaQuery('(min-width: 800px)');

  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = (e) => {
    if (e.target && (e.target.dataset['toggleButton'] != null || e.target.dataset['togglebutton'] != null)) {
      setIsOpen((v) => !v);
      return;
    } else {
      const hasToggleButton = e.target.closest('[data-togglebutton]');
      if (hasToggleButton) {
        setIsOpen((v) => !v);
      }
    }

    let bgClick = props.properties.bgClick;
    if (props.properties.slidingMenu && !isDesktop) {
      bgClick = true;
    }

    if (!bgClick) {
      const inOffCanvas = e.target.closest('.offcanvas');
      if (inOffCanvas) return;
    }
    setIsOpen((v) => !v);
  };

  const {
    properties: { bodyClassName, rootClassName },
  } = componentProps;

  React.useEffect(() => {
    if (bodyClassName) {
      if (isOpen) {
        document.body.classList.add(bodyClassName);
      } else {
        document.body.classList.remove(bodyClassName);
      }
    }
    return () => {
      if (bodyClassName) {
        document.body.classList.remove(bodyClassName);
      }
    };
  }, [isOpen, bodyClassName]);

  let backdrop = props.properties.backdrop;
  let scrollable = props.properties.scrollable;
  if (props.properties.slidingMenu && !isDesktop) {
    backdrop = true;
    scrollable = false;
  }

  return (
    <MenuButtonDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style} onClick={toggle}>
      <LinkedElement
        linkedId="menu-button-children"
        refId={props.refId}
        refType={props.refType}
        nodeId={props.nodeId}
      />
      {isOpen && (
        <Offcanvas
          backdrop={backdrop}
          fade={props.properties.fade}
          direction={props.properties.direction}
          scrollable={scrollable}
          keyboard={props.properties.keyboard != null ? props.properties.keyboard : true}
          isOpen={isOpen}
          toggle={toggle}
          rootClassName={rootClassName ?? ''}
          className={'offcanvas-custom'}
        >
          <OffcanvasHeader className="offcanvas-header-custom">
            <LinkedElement
              linkedId="offcanvas-header"
              refId={props.refId}
              refType={props.refType}
              nodeId={props.nodeId}
            />
          </OffcanvasHeader>
          <OffcanvasBody className="offcanvas-body-custom">
            <LinkedElement
              linkedId="offcanvas-body"
              refId={props.refId}
              refType={props.refType}
              nodeId={props.nodeId}
            />
          </OffcanvasBody>
        </Offcanvas>
      )}
    </MenuButtonDiv>
  );
};
