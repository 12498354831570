import React from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import urlonStringify from './urlon';

import { updateGamesCache } from './game-info-api-cache';

const apiUrl = window.config.dataSourceApiUrl;

const cache: any = {
  loading: {},
  ref: {},
};
window._gsi_cache = cache;

type GameInfoProps = {
  dsId?: string;
  gameIds: string[];
  authenticationToken?: string;
};

const digestMessage = async (message: string[]) => {
  const data = new TextEncoder().encode(JSON.stringify(message));
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
};

const resolveGamesInfo = ({ dsId, gameIds, authenticationToken }: GameInfoProps) => {
  // console.log('resolveGameInfo', { dsId, gameIds, authenticationToken });
  const [state, setState] = React.useState<{ data: any; loaded: boolean; digestKey: string }>({
    data: {
      id: null,
      data: null,
    },
    loaded: false,
    digestKey: '',
  });

  React.useEffect(() => {
    if (gameIds && gameIds.length > 0)
      digestMessage(gameIds).then((resp) => {
        setState((v) => ({
          ...v,
          digestKey: resp,
        }));
      });
  }, [gameIds]);

  //console.log('digestKey', state.digestKey);

  const processResponse = (stillMounted: boolean, resp: any, dsId: string, gameIds: string[], digestKey: string) => {
    let data = null;
    if (resp?.data?.data) {
      data = resp.data.data;
    }

    const final = {
      id: dsId,
      gameIds: gameIds,
      data: data,
    };

    if (cache.ref[digestKey]) cache[digestKey] = final;

    if (Array.isArray(data)) {
      updateGamesCache(data, dsId);
    }

    if (stillMounted) {
      setState({
        data: final,
        loaded: true,
        digestKey,
      });
    }

    return { [digestKey]: final };
  };

  React.useEffect(() => {
    let stillMounted = true;

    const digestKey = state.digestKey;

    if (authenticationToken && dsId && digestKey && gameIds?.length) {
      if (cache.ref[digestKey] == null) cache.ref[digestKey] = 0;
      cache.ref[digestKey] += 1;

      if (cache[digestKey]) {
        const data = cloneDeep(cache[digestKey]);
        setState({
          data: data,
          loaded: true,
          digestKey,
        });
        return;
      } else if (cache.loading[digestKey]) {
        cache.loading[digestKey].promise
          .then((data: any) => {
            if (!stillMounted) return;
            if (!data[digestKey]) return;

            setState({
              data: data[digestKey],
              loaded: true,
              digestKey,
            });
          })
          .catch((e: Error) => {
            console.log(e);
            setState((v) => ({ ...v, loading: false }));
          });
        return;
      } else {
        cache.loading[digestKey] = {};
        cache.loading[digestKey].promise = new Promise((resolve, reject) => {
          const strGamesIds = gameIds.map((id) => id.toString());
          axios
            .get(`${apiUrl}/resolve/source`, {
              headers: {
                Authorization: 'Bearer ' + authenticationToken,
              },
              params: {
                q: urlonStringify({
                  id: dsId,
                  filter: [{ name: 'id', values: strGamesIds, op: 'in' }],
                }),
              },
            })
            .then((resp) => {
              resolve(processResponse(stillMounted, resp, dsId, gameIds, digestKey));
            })
            .catch((e: Error) => {
              console.log(e);
              setState((v) => ({ ...v, loading: false }));
              reject(e);
            });
        });
      }
    }

    return () => {
      stillMounted = false;
    };
  }, [dsId, state.digestKey, gameIds, authenticationToken]);

  return state;
};

export default resolveGamesInfo;
