const FONT_FAMILY = 'Nudista , sans-serif';

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const getParsedValue = (val, def) => {
  const parsedValue = parseFloat(val);
  if (!isNaN(parsedValue)) return parsedValue;
  return def;
};

const getString = (val, def) => {
  if (val) return val;
  return def;
};

const arcPath = (x, y, radius, endRadius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const start2 = polarToCartesian(x, y, endRadius, endAngle);
  const end2 = polarToCartesian(x, y, endRadius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
    'L',
    end2.x,
    end2.y,
    'A',
    endRadius,
    endRadius,
    0,
    largeArcFlag,
    1,
    start2.x,
    start2.y,
    'Z',
  ].join(' ');
  return d;
};

const initProductWheel = (element, config) => {
  const slot_count = config.slots.length;
  let svg = '';
  element.innerHTML = svg;

  let commonAngle = 360 / slot_count;
  let fixedAngles = 0;
  let fixedSlots = 0;
  let i = 0;

  for (i = 0; i < slot_count; i++) {
    const slot = config.slots[i];
    if (slot.angle) {
      fixedAngles += parseFloat(slot.angle);
      fixedSlots += 1;
    }
  }

  if (fixedSlots) {
    commonAngle = (360 - fixedAngles) / (slot_count - fixedSlots);
  }

  let startAngle = getParsedValue(config.animation.spin, 0);

  for (i = 0; i < slot_count; i++) {
    const slot = config.slots[i];

    let endAngle = startAngle + commonAngle;

    if (slot.angle) {
      endAngle = startAngle + parseFloat(slot.angle);
    }

    let t;
    let middleAngle = (startAngle + endAngle) / 2;
    let rotateAngle = startAngle + (endAngle - startAngle) / 2;

    svg += `<path d="${arcPath(50, 50, 33, 50, startAngle, endAngle)}" fill="${slot.color}" stroke="none" stroke-width="0" />`;

    if (slot.image) {
      t = polarToCartesian(50, 50, 41, middleAngle);
      svg += `<image href="${slot.image}"  x="${t.x}" y="${t.y}" width="${slot.imageWidth}" height="${slot.imageHeight}" transform-origin="${t.x},${t.y}" transform="rotate(${rotateAngle} ${t.x},${t.y}) translate(-${slot.imageWidth / 2}, -${slot.imageHeight / 2}) "/>`;
    }

    t = polarToCartesian(50, 50, getParsedValue(slot.titleTop, 43), middleAngle);
    svg += `<text font-size="${getParsedValue(slot.titleFontSize, 6)}" x="${t.x}" y="${t.y}" fill="${getString(slot.titleColor, '#fff')}" font-style="600" font-family=${FONT_FAMILY} class="oswald-text" alignment-baseline="central" text-anchor="middle" transform="rotate(${rotateAngle} ${t.x},${t.y})">${slot.title}</text>`;

    t = polarToCartesian(50, 50, getParsedValue(slot.subtitleTop, 37), middleAngle);
    svg += `<text font-size="${getParsedValue(slot.subtitleFontSize, 3)}" x="${t.x}" y="${t.y}" fill="${getString(slot.subtitleColor, '#DED9FF')}" font-style="600" font-family=${FONT_FAMILY} class="oswald-text" alignment-baseline="central" text-anchor="middle" transform="rotate(${rotateAngle} ${t.x},${t.y})">${slot.subtitle}</text>`;

    startAngle = endAngle;
  }

  element.innerHTML = svg;
};

const initValueWheel = (element, config) => {
  const slot_count = config.slots.length;
  let svg = '';
  element.innerHTML = svg;

  let commonAngle = 360 / slot_count;
  let fixedAngles = 0;
  let fixedSlots = 0;
  let i = 0;

  for (i = 0; i < slot_count; i++) {
    const slot = config.slots[i];
    if (slot.angle) {
      fixedAngles += parseFloat(slot.angle);
      fixedSlots += 1;
    }
  }

  if (fixedSlots) {
    commonAngle = (360 - fixedAngles) / (slot_count - fixedSlots);
  }

  let startAngle = getParsedValue(config.animation.spin, 0);

  for (i = 0; i < slot_count; i++) {
    const slot = config.slots[i];

    let endAngle = startAngle + commonAngle;

    if (slot.angle) {
      endAngle = startAngle + parseFloat(slot.angle);
    }

    let middleAngle = (startAngle + endAngle) / 2;

    let t;

    let strokeColor = '#E8B425';
    let strokeWidth = '.3';

    if (slot.title === '100') {
      strokeColor = '#FFF';
      strokeWidth = '1';
      startAngle = endAngle;
      continue;
    }

    svg += `<path d="${arcPath(50, 50, 0, 33, startAngle, endAngle)}" fill="${slot.color}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />`;

    let rotateAngle = startAngle + (endAngle - startAngle) / 2;

    t = polarToCartesian(50, 50, getParsedValue(slot.titleTop, 28), middleAngle);
    svg += `<text font-size="${getParsedValue(slot.titleFontSize, 7)}" x="${t.x}" y="${t.y}" fill="${getString(slot.titleColor, '#fff')}" font-style="600" font-family=${FONT_FAMILY} class="oswald-text" alignment-baseline="central" text-anchor="middle" transform="rotate(${rotateAngle} ${t.x},${t.y})">${slot.title}</text>`;

    startAngle = endAngle;
  }

  startAngle = getParsedValue(config.animation.spin, 0);

  for (i = 0; i < slot_count; i++) {
    const slot = config.slots[i];

    let endAngle = startAngle + commonAngle;

    if (slot.angle) {
      endAngle = startAngle + parseFloat(slot.angle);
    }

    let middleAngle = (startAngle + endAngle) / 2;

    let t;

    let strokeColor = '#FFF';
    let strokeWidth = '1';

    if (slot.title !== '100') {
      startAngle = endAngle;
      continue;
    }

    svg += `<path d="${arcPath(50, 50, 0, 33, startAngle, endAngle)}" fill="${slot.color}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />`;

    let rotateAngle = startAngle + (endAngle - startAngle) / 2;
    t = polarToCartesian(50, 50, getParsedValue(slot.titleTop, 28), middleAngle);

    svg += `<text font-size="${getParsedValue(slot.titleFontSize, 7)}" x="${t.x}" y="${t.y}" fill="${getString(slot.titleColor, '#fff')}" font-style="600" font-family=${FONT_FAMILY} class="oswald-text" alignment-baseline="central" text-anchor="middle" transform="rotate(${rotateAngle} ${t.x},${t.y})">${slot.title}</text>`;

    startAngle = endAngle;
  }

  element.innerHTML = svg;
};

const initMultiplicatorWheel = (element, config) => {
  const slot_count = config.slots.length;
  let svg = '';
  element.innerHTML = svg;

  let commonAngle = 360 / slot_count;
  let fixedAngles = 0;
  let fixedSlots = 0;
  let i = 0;

  for (i = 0; i < slot_count; i++) {
    const slot = config.slots[i];
    if (slot.angle) {
      fixedAngles += parseFloat(slot.angle);
      fixedSlots += 1;
    }
  }

  if (fixedSlots) {
    commonAngle = (360 - fixedAngles) / (slot_count - fixedSlots);
  }

  let startAngle = getParsedValue(config.animation.spin, 0);

  for (i = 0; i < slot_count; i++) {
    const slot = config.slots[i];

    let endAngle = startAngle + commonAngle;

    if (slot.angle) {
      endAngle = startAngle + parseFloat(slot.angle);
    }

    let middleAngle = (startAngle + endAngle) / 2;

    let t;

    svg += `<path d="${arcPath(50, 48, 0, 39, startAngle, endAngle)}" fill="${slot.color}" stroke="none" stroke-width="0" />`;

    let rotateAngle = startAngle + (endAngle - startAngle) / 2;

    t = polarToCartesian(50, 48, getParsedValue(slot.titleTop, 28), middleAngle);
    svg += `<text font-size="${getParsedValue(slot.titleFontSize, 10)}" x="${t.x}" y="${t.y}" fill="${getString(slot.titleColor, '#fff')}" font-style="600" font-family=${FONT_FAMILY} class="oswald-text" alignment-baseline="central" text-anchor="middle" transform="rotate(${180 + rotateAngle} ${t.x},${t.y})">${slot.title}</text>`;

    startAngle = endAngle;
  }

  element.innerHTML = svg;
};

const imgPath = (fileName, def) => {
  if (def && (def.toLowerCase().indexOf('http') === 0 || def.toLowerCase().indexOf('data:image/') === 0)) {
    return def;
  }
  return `https://winner-p.b-cdn.net/winner/wheel-assets/${fileName}`;
};

export { initProductWheel, initValueWheel, initMultiplicatorWheel, imgPath };
