import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Odometer from './jackpotCounter';
import { connect } from 'react-redux';

import * as storeActions from '../../../modules/casino/store/actions';

const INITIAL_OFFSET = 5;
const COUNTER_INITIAL_STEPS = 30;
const COUNTER_REFRESH_STEPS = 10;

class ReactOdometer extends PureComponent {
  // Information about options can be found here:
  // http://github.hubspot.com/odometer/
  static propTypes = {
    storageKey: PropTypes.string,
    fractionalDigits: PropTypes.number,
    digits: PropTypes.number,
    styles: PropTypes.shape({
      digitsWrapperClassName: PropTypes.string,
      digitsWrapper: PropTypes.string,
      digitsInnerWrapperClassName: PropTypes.string,
      digitsInnerWrapper: PropTypes.string,
      digitClassName: PropTypes.string,
      digit: PropTypes.string,
      decimalsWrapperClassName: PropTypes.string,
      decimalsWrapper: PropTypes.string,
      decimalsInnerWrapperClassName: PropTypes.string,
      decimalsInnerWrapper: PropTypes.string,
      decimalClassName: PropTypes.string,
      decimal: PropTypes.string,
      radixClassName: PropTypes.string,
      radix: PropTypes.string,
    }),
    initialOffset: PropTypes.number,
    counterInitialSteps: PropTypes.number,
    counterRefreshSteps: PropTypes.number,
    isJackpot: PropTypes.bool,
    simpleText: PropTypes.bool,
  };

  static defaultProps = {
    isJackpot: false,
    simpleText: false,
    storageKey: '',
    fractionalDigits: 2,
    digits: 6,
    styles: {
      digitsWrapper:
        'height: 14px;line-height: 13px;overflow-y: hidden;background-size: 100%;text-align: center;padding: 0 1px 0 0;color:#000;',
      digitsInnerWrapper: '&.disabled {color: rgba(0, 0, 0, 0.3);}',
      digit: 'font-size: 14px;font-weight: 400;',
      decimalsWrapper:
        'height: 14px;line-height: 13px;overflow-y: hidden;background-size: 100%;text-align: center;padding: 0 1px 0 0;color:#000;',
      decimalsInnerWrapper: 'font-size: 14px;font-weight: 400;',
      decimal: 'font-size: 14px;font-weight: 400;',
      radix:
        'height: 14px;line-height: 13px;display: inline-block;width: 0;overflow: visible;position: relative;z-index: 1;top: 0;left: -2px;font-size: 14px;font-weight: 600;padding: 0 1px;',
      digitsSeparatorWrapper:
        'display:flex; height: 14px;line-height: 13px;overflow-y: hidden;background-size: 100%;text-align: center;padding: 0 1px 0 0;color:#000;',
    },
    initialOffset: INITIAL_OFFSET,
    counterInitialSteps: COUNTER_INITIAL_STEPS,
    counterRefreshSteps: COUNTER_REFRESH_STEPS,
  };

  constructor(props) {
    super(props);

    let initValue = props.value - props.initialOffset;

    if (props.storageKey !== '') {
      const siv = localStorage.getItem(`jackpotValue${props.storageKey}`);
      if (siv !== null && siv !== '') {
        const iv = parseFloat(siv);
        if (!isNaN(iv) && iv <= props.value && iv >= props.value - props.initialOffset) {
          initValue = iv;
        }
      }
    }

    if (initValue > props.value) {
      initValue = props.value - props.initialOffset;
    }

    //if (initValue < props.value - props.initialOffset) {
    //  initValue = props.value - props.initialOffset;
    //}

    if (initValue < 0) {
      initValue = 0;
    }

    let { value, digits, fractionalDigits } = props;

    // check if the number of digits is enough to represent
    let maxValue = Math.pow(10, digits);

    while (value >= maxValue) {
      digits = digits + 1;
      fractionalDigits = fractionalDigits - 1;
      maxValue = Math.pow(10, digits);
    }

    this.state = {
      initValue: initValue,
      currentValue: initValue,
      nextValue: 0,
      value: this.props.value,
      cValues: [],
      digits,
      fractionalDigits,
    };

    this.timer = 0;
    this.node = React.createRef();

    this.mounted = true;
  }

  computeSteps = (sv, dv, initial = true) => {
    // normalize values
    sv = parseFloat(sv);
    dv = parseFloat(dv);

    // do nothing if we got bad values
    if (isNaN(sv) || isNaN(dv)) {
      return;
    }

    // maximum 2 decimals
    sv = parseFloat(sv.toFixed(2));
    dv = parseFloat(dv.toFixed(2));

    let { digits, fractionalDigits } = this.state;

    // check if the number of digits is enough to represent
    let maxValue = Math.pow(10, digits);

    while (dv >= maxValue) {
      digits = digits + 1;
      fractionalDigits = fractionalDigits - 1;

      maxValue = Math.pow(10, digits);
    }

    // if the next value is less then the current one, reset the counter
    if (dv < sv) {
      this.setState({
        digits,
        fractionalDigits,
        cValues: [dv],
      });
      return;
    }

    if (sv === dv) {
      // prevent compute steps if the values are the same
      this.setState((state) => {
        const { cValues } = state;
        const ncValues = [...cValues, parseFloat(sv).toFixed(2)];

        return {
          digits,
          fractionalDigits,
          cValues: ncValues,
        };
      });
      return;
    }

    // compute the total difference
    let dc = dv - sv;

    // initial number of steps
    let steps = initial ? this.props.counterInitialSteps : this.props.counterRefreshSteps;

    // adjust number of steps for small values
    let minSteps = Math.floor(dc / 0.03);

    // if the difference is very small, just set it to the destination value
    if (minSteps === 0) {
      this.setState({
        digits,
        fractionalDigits,
        cValues: [dv],
      });
      return;
    }

    if (minSteps < steps) {
      steps = minSteps;
    }

    const dcp = dc / steps;

    let parts = [];
    let sum = 0,
      p = 0,
      lastP = 0;

    for (let i = 0; i < steps; i++) {
      p = 0.01 + Math.random();

      while (Math.abs(p - lastP) > dcp) {
        p = 0.01 + Math.random();
      }

      lastP = p;

      //p = 0.01 + Math.random();

      sum += p;
      parts.push(p);
    }

    let ps = 0;
    let lv = 0;
    let nv = [];
    for (let i = 0; i < steps - 1; i++) {
      let v = sv + ((ps + parts[i]) / sum) * dc;
      v = parseFloat(v.toFixed(2));
      if (v === lv) {
        v += 0.01;
      }
      lv = v;
      nv.push(v);
      ps += parts[i];
    }

    nv.push(dv.toFixed(2));

    this.setState((state) => {
      const { cValues } = state;

      return {
        digits,
        fractionalDigits,
        cValues: nv,
      };
    });
  };

  nextStep = () => {
    if (!this.mounted) return;

    let [nextValue, ...rest] = this.state.cValues;

    nextValue = nextValue || 0;

    const newState = {
      cValues: rest,
      nextValue,
    };

    if (nextValue !== 0) {
      newState['currentValue'] = nextValue;
    } else {
      return;
    }

    this.setState(
      () => newState,
      () => this.saveJackpotValue(),
    );
  };

  componentDidMount() {
    this.computeSteps(this.state.currentValue, this.state.value);
    setTimeout(this.nextStep, 0);
    this.timer = setInterval(this.nextStep, 1000);

    if (this.props.isJackpot) {
      //this.props.incRefCount();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentValue, value } = this.state; // eslint-disable-line

    if (prevProps.value !== this.props.value) {
      this.computeSteps(currentValue, this.props.value, false);
      this.setState({
        value: this.props.value,
      });
    }
  }

  saveJackpotValue = () => {
    if (this.props.storageKey !== '') {
      const jn = `jackpotValue${this.props.storageKey}`;
      localStorage.setItem(jn, this.state.currentValue);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
    if (this.timer !== 0) {
      clearInterval(this.timer);
    }

    if (this.props.isJackpot) {
      this.saveJackpotValue();
      //this.props.decRefCount();
    }
  }

  render() {
    if (this.props.simpleText) {
      let value = this.state.currentValue?.toFixed ? this.state.currentValue?.toFixed?.(2) : this.state.currentValue;
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return <React.Fragment>{value}</React.Fragment>;
    }

    return (
      <Odometer
        digits={this.state.digits}
        fractionalDigits={this.state.fractionalDigits}
        value={this.state.currentValue}
        styles={this.props.styles}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    incRefCount: () => dispatch(storeActions.incJackpotRefCount()),
    decRefCount: () => dispatch(storeActions.decJackpotRefCount()),
  };
};

export default connect(null, mapDispatchToProps)(ReactOdometer);
