export const appConstants = {
  INITIALIZE: 'LIVE_CASINO_INITIALIZE',
  INITIALIZED: 'LIVE_CASINO_INITIALIZED',
  SUBSCRIBE: 'LIVE_CASINO_SUBSCRIBE',
  SUBSCRIBED: 'LIVE_CASINO_SUBSCRIBED',
  UNSUBSCRIBE: 'LIVE_CASINO_UNSUBSCRIBE',
  UNSUBSCRIBED: 'LIVE_CASINO_UNSUBSCRIBED'
};

export const tablesConstants = {
  STATE_INI: 'LIVE_CASINO_STATE_INI',
  STATE_UPDATE: 'LIVE_CASINO_STATE_UPDATE',
};
