import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { fetchFreeBetsDumpHistory } from '../../../modules/casino/store/sagas/free_bets';
import { fetchFreeSpinsDumpHistory } from '../../../modules/casino/store/sagas/free_spins';
import { fetchTransactionsDumpHistory } from '@/modules/casino/store/sagas/transactions';
import './index.scss';

type TransactionsHistoryProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const TransactionsHistory = (componentProps: TransactionsHistoryProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const { t } = useTranslation();

  const [activeSelection, setActiveSelection] = useState(2);
  const [isOpened, setIsOpened] = useState(false);
  const currentCurrency = useAppSelector((state) => state.currencies.currentCurrency);
  const [isLoading, setIsLoading] = useState(false);

  const processFreeBetDump = (list: any) => {
    const listData: any = [];

    if (list) {
      list?.sort((a: any, b: any) => {
        if (a?.created < b?.created) {
          return -1;
        }
        if (a?.created > b?.created) {
          return 1;
        }
        return 0;
      });

      const bonusStatus: any = {
        4: 'Canceled',
        6: 'Collected',
      };

      const handleList = (item: any) => {
        const name = item.name;
        const currency = currentCurrency ?? 'RON';

        let title = null;
        let amount = null;
        if (item.status === 4) {
          title = `${item.count} x ${formatCurrency(item.amount, item.amount_small)} ${currency}`;
          amount = `-${formatCurrency(item.amount * item.count, item.amount_small)} ${currency}`;
        } else if (item.status === 6) {
          title = `${item.count} x ${formatCurrency(item.amount, item.amount_small)} ${currency}`;
          amount = `${formatCurrency(item.collected, item.collected_small)} ${currency}`;
        }

        listData.push([
          moment.unix(item.created).format('DD/MM/YYYY HH:mm:ss'),
          name,
          title,
          amount,
          t(bonusStatus[item.status]),
        ]);
      };

      list.forEach(handleList);

      listData.unshift([t('Event Date'), t('Name'), t('Tokens'), t('Result'), t('Status')]);

      exportToCsv(t('free_bet'), listData);
    }
  };

  const processFreeSpinsDump = (list: any) => {
    const listData: any = [];

    if (list) {
      list?.sort((a: any, b: any) => {
        if (a?.insert_date < b?.insert_date) {
          return -1;
        }
        if (a?.insert_date > b?.insert_date) {
          return 1;
        }
        return 0;
      });

      const bonusStatus: any = {
        0: 'other',
        1: 'active',
        2: 'inactive',
        3: 'expired',
        4: 'canceled',
        6: 'collected',
      };

      const handleList = (item: any) => {
        const name = item.bonus_name;
        const title = `${item.remaining_fs}/${item.total_fs} ${'Spins'}`;
        const amount = '';

        listData.push([
          moment.unix(item.insert_date).format('DD/MM/YYYY HH:mm:ss'),
          name,
          title,
          amount,
          t(bonusStatus[item.bonus_status_id]),
        ]);
      };

      list.forEach(handleList);

      listData.unshift([t('Event Date'), t('Name'), t('Tokens'), t('Result'), t('Status')]);

      exportToCsv(t('free_spins'), listData);
    }
  };

  const processTransactionsDump = (list: any) => {
    const listData: any = [];

    if (list) {
      list?.sort((a: any, b: any) => {
        if (a.status_change_date && b.status_change_date) {
          if (a?.status_change_date > b?.status_change_date) {
            return -1;
          }
          if (a?.status_change_date < b?.status_change_date) {
            return 1;
          }
        } else if (!a.status_change_date && b.status_change_date) {
          if (a?.insert_date > b?.status_change_date) {
            return -1;
          }
          if (a?.insert_date < b?.status_change_date) {
            return 1;
          }
        } else if (a.status_change_date && !b.status_change_date) {
          if (a?.status_change_date > b?.insert_date) {
            return -1;
          }
          if (a?.status_change_date < b?.insert_date) {
            return 1;
          }
        } else {
          if (a?.insert_date > b?.insert_date) {
            return -1;
          }
          if (a?.insert_date < b?.insert_date) {
            return 1;
          }
        }
        return 0;
      });

      const handleList = (item: any) => {
        let type = 'Unknown';
        let location = 'Unknown';
        let date = item.insert_date;

        const status: any = {
          0: 'Unknown',
          1: 'Pending Approval',
          2: 'Ready For Cash-in',
          3: 'Rejected',
          4: 'Processed',
          5: 'Cancelled',
        };

        const DEPOSIT = [1, 24, 53, 114, 130, 140, 231];
        const WITHDRAW = [11, 12, 25, 26, 27, 28, 54, 55, 56, 120, 121, 122, 141, 142, 143, 232, 233, 234];
        const TYPE_HAPPY_HOUR = 30;
        const TYPE_RETAIL_TICKET_CASH_IN = 85;

        if (DEPOSIT.includes(item.type)) {
          location = 'Online Deposit';
          type = 'Deposit';
        }
        if (WITHDRAW.includes(item.type)) {
          location = 'Online Withdrawal';
          type = 'Withdraw';
          date = item.status_change_date;
        }
        if (item.type === TYPE_RETAIL_TICKET_CASH_IN) {
          location = 'Retail ticket payment';
          type = 'Retail ticket payment';
        }
        if (item.type === TYPE_HAPPY_HOUR) {
          location = 'Happy Hour';
          type = 'Happy Hour';
        }

        const amount = `${formatCurrency(parseFloat(item.amount), 0)} ${currentCurrency}`;
        const tax = `${formatCurrency(parseFloat(item.tax), 0)} ${currentCurrency}`;

        listData.push([
          item.id,
          t(type),
          t(location),
          amount,
          tax,
          t(status[item.status]),
          moment.unix(date).format('DD/MM/YYYY HH:mm:ss'),
        ]);
      };

      list.forEach(handleList);

      listData.unshift([t('ID'), t('Type'), t('Location'), t('Amount'), t('Tax'), t('Status'), t('Event Date')]);
      exportToCsv(t('in_out'), listData);
    }
  };

  const processBonusDump = (list: any) => {
    const listData: any = [];
    const data = list?.RON?.bonus;
    if (data) {
      data?.sort((a: any, b: any) => {
        if (a?.startDate < b?.startDate) {
          return -1;
        }
        if (a?.startDate > b?.startDate) {
          return 1;
        }
        return 0;
      });

      const handleList = (item: any) => {
        const amount = `${formatCurrency(parseFloat(item.amount), 0)} ${currentCurrency}`;
        const amountGranted = `${formatCurrency(parseFloat(item.amountGranted), 0)} ${currentCurrency}`;
        const bonusStatus: any = {
          0: 'Other',
          1: 'Active',
          2: 'Inactive',
          3: 'Expired',
          4: 'Forfeited',
          5: 'Wagered',
          6: 'Consumed',
        };
        listData.push([
          moment.unix(item.startDate).format('DD/MM/YYYY HH:mm:ss'),
          item.name,
          t(bonusStatus[item.status]),
          amount,
          amountGranted,
        ]);
      };

      data?.forEach(handleList);

      listData.unshift([t('Event Date'), t('Name'), t('Status'), t('Amount'), t('Amount Granted')]);
      exportToCsv(t('bonus'), listData);
    }
  };

  const processGamesDump = (list: any) => {
    const listData: any = [];

    if (list) {
      list?.sort((a: any, b: any) => {
        if (a?.spin_timestamp < b?.spin_timestamp) {
          return -1;
        }
        if (a?.spin_timestamp > b?.spin_timestamp) {
          return 1;
        }
        return 0;
      });

      const handleList = (item: any) => {
        const bet = `${formatCurrency(parseFloat(item.bet), 0)} ${currentCurrency}`;
        const win = `${formatCurrency(parseFloat(item.win), 0)} ${currentCurrency}`;
        listData.push([moment.unix(item.spin_timestamp).format('DD/MM/YYYY HH:mm:ss'), item.game, bet, win]);
      };

      list.forEach(handleList);

      listData.unshift([t('Event Date'), t('Game'), t('Bet'), t('Win')]);
      exportToCsv(t('games'), listData);
    }
  };

  const exportToCsv = (filename: string, rows: any) => {
    const processRow = (row: any) => {
      let finalVal = '';
      for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? '' : row[j]?.toString();
        if (row[j] instanceof Date) {
          innerValue = row[j]?.toLocaleString();
        }
        let result = innerValue?.replace(/"/g, '""');
        if (result?.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

    const date = moment();

    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${filename}_${date.format('DDMMYYYYhhmmss')}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const formatCurrency = (value: number, small: number) => {
    if (small === 1) value = value / 100;

    if (isNaN(value)) {
      return 0;
    }

    return value
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      .replace(',00', '');
  };

  const onDownloadCSV = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.id) {
      const id = parseInt(e.currentTarget.dataset.id);
      if (id) {
        switch (id) {
          case 1: // Games
          case 2: // InOut
          case 3: // Bonus
            const transactionsDumpReq = fetchTransactionsDumpHistory(id);
            setIsLoading(true);
            transactionsDumpReq
              .then((list: any) => {
                if (id === 1) {
                  processGamesDump(list);
                  setIsLoading(false);
                }
                if (id === 2) {
                  processTransactionsDump(list);
                  setIsLoading(false);
                }
                if (id === 3) {
                  processBonusDump(list);
                  setIsLoading(false);
                }
              })
              .catch((e: any) => {
                setIsLoading(false);
              });
            break;
          case 4: // Free Bet
            const fbHistoryDumpReq = fetchFreeBetsDumpHistory();
            setIsLoading(true);
            fbHistoryDumpReq
              .then((list) => {
                processFreeBetDump(list);
                setIsLoading(false);
              })
              .catch((e) => {
                setIsLoading(false);
              });
            break;
          case 5: // Free Spins
            const fsHistoryDumpReq = fetchFreeSpinsDumpHistory();
            setIsLoading(true);
            fsHistoryDumpReq
              .then((list) => {
                processFreeSpinsDump(list);
                setIsLoading(false);
              })
              .catch((e) => {
                setIsLoading(false);
              });
            break;

          default:
            break;
        }
      }
    }
  };

  const onChangeSelect = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.id) {
      const id = parseInt(e.currentTarget.dataset.id);
      setActiveSelection(id);
      setIsOpened(false);
    }
  };

  const onToggleOpened = () => {
    setIsOpened(!isOpened);
  };

  const contextValue = {
    onDownloadCSV: (e: React.MouseEvent<HTMLElement>) => onDownloadCSV(e),
    loading: isLoading,
    activeSelection: activeSelection,
    onChangeSelect: onChangeSelect,
    isOpened: isOpened,
    onToggleOpened: onToggleOpened,
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default TransactionsHistory;
