import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import VivaAPI from '../../ClientAPI/VivaAPI';
import * as paths from '../../ClientAPI/paths';
import Utils from '../../utils/Utils';
import PlayerAbuseChecker from '../../utils/PlayerAbuseChecker';
import { HTML_3DS_IFRAME_ID, PaymentProvider as PaymentProviderConstant } from '@/constants/paymentProvider';

export function* requestPaymentLinkSaga(action) {
  const axios = ClientAPI.getInstance();
  // const isSkrill = true;//action.isSkrill;
  try {
    let data = {
      amount: action.isSkrill ? action.amount * 100 : action.amount,
      bonus_id: action.bonus_id,
      events: getLogEvents(),
      currencyCode: 'RON',
      languageCode: 'ro',
    };

    let pac = PlayerAbuseChecker.getInfo();
    data = {
      ...pac,
      ...data,
    };
    const response = yield axios({
      url: action.isSkrill ? paths.paymentCheckout.SKRILL_PAYMENT_CHECKOUT : paths.paymentCheckout.PAYMENT_CHECKOUT,
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Payment Checkout response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Payment Checkout response has no 'result' property");
    }

    window.localStorage.setItem(
      'deposit',
      JSON.stringify({
        amount: data.amount,
        bonusId: data.bonus_id,
        step: 'final-step',
        paymentProvider: action.isSkrill ? PaymentProviderConstant.skrill : PaymentProviderConstant.safecharge,
      }),
    );
    if (action.isSkrill) {
      if (response.result?.sessionId) {
        response.result = {
          link: `https://pay.skrill.com/?sid=${response.result.sessionId}&return_url=${window.location.protocol}//${
            window.location.hostname
          }${window.location.port ? ':' + window.location.port : ''}/deposit`,
        };
      }
    }
    yield put(actions.receivedPaymentLink(response.result));
  } catch (error) {
    yield put(actions.receivedPaymentLink({ error: true }));
    return;
  }
}

function getLogEvents(max = 50) {
  let events = ClientAPI.getStore().getState().application.eventLog;
  if (events) {
    events.slice(-50);
  } else {
    events = [];
  }

  return events;
}

export function* requestVivaTokenSaga(action) {
  const axios = ClientAPI.getInstance();

  try {
    const response = yield axios({
      url: paths.paymentCheckout.VIVA_TOKEN,
      method: 'get',
      data: {
        events: getLogEvents(),
      },
    });

    if (!response) {
      throw new Error('[ERROR] Request Viva Token response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request Viva Token response has no 'result' property");
    }

    if (response.result && response.result.access_token) {
      VivaAPI.setToken(response.result.access_token);
    }

    yield put(actions.receivedVivaToken(true));
  } catch (error) {
    yield put(actions.receivedVivaToken(false));
    console.log(error);
    return;
  }
}

export function* requestVivaCardTokenSaga(action) {
  const axios = yield Utils.getAxiosWithToken();
  try {
    const response = yield axios({
      url: paths.paymentCheckout.VIVA_CARD_TOKEN,
      method: 'get',
      data: {
        events: getLogEvents(),
      },
    });

    if (!response) {
      throw new Error('[ERROR] Request Viva Card Token response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request Viva Card Token response has no 'result' property");
    }

    yield put(actions.receivedCardToken(response));
  } catch (error) {
    console.log(error);
    return;
  }
}
export function* requestBPCardTokenSaga(action) {
  const axios = yield Utils.getAxiosWithToken();
  try {
    const response = yield axios({
      // url: paths.paymentCheckout.VIVA_CARD_TOKEN,
      url: paths.paymentCheckout.BP_CARD_TOKEN,
      method: 'get',
      data: {
        events: getLogEvents(),
      },
    });

    if (!response) {
      throw new Error('[ERROR] Request Viva Card Token response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request Viva Card Token response has no 'result' property");
    }

    yield put(actions.receivedCardToken(response));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* requestVivaWithdrawSaga(action) {
  const axios = ClientAPI.getInstance();
  try {
    let data = {
      amount: action.amount,
      cardToken: action.token,
      events: getLogEvents(),
    };
    let pac = PlayerAbuseChecker.getInfo();
    data = {
      ...pac,
      ...data,
    };
    const response = yield axios({
      url: paths.paymentCheckout.VIVA_WITHDRAW,
      method: 'POST',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Request Viva Card Token response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request Viva Card Token response has no 'result' property");
    }
    yield put(actions.receivedWithdraw(response.result));
    yield put(actions.requestWallet());
    yield put(actions.requestPendingVivaWithdrawals());
  } catch (error) {
    console.log(error);
    return;
  }
}
export function* requestBPWithdrawSaga(action) {
  const axios = ClientAPI.getInstance();
  const currentCurrency = ClientAPI.getStore().getState().currencies.currentCurrency;
  console.log(action);
  try {
    let data = {
      amount: action.amount,
      currencyCode: currentCurrency,
      paymentType: 'credit_card',
      paymentTokenId: action.token,
      events: getLogEvents(),
      providerName: 'checkout_com',
    };
    let pac = PlayerAbuseChecker.getInfo();
    data = {
      ...pac,
      ...data,
    };
    console.log('data', data);
    const response = yield axios({
      url: paths.paymentCheckout.BP_WITHDRAW,
      method: 'POST',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Request Viva Card Token response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request Viva Card Token response has no 'result' property");
    }
    yield put(actions.receivedWithdraw(response.result));
    yield put(actions.requestWallet());
    yield put(actions.requestPendingVivaWithdrawals());
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* sendVivaTokenSaga(action) {
  const axios = ClientAPI.getInstance();
  let amount = null;
  let bonusId = null;
  let chargeToken = null;
  let redirectToACSForm = null;

  if (action && action.data && action.amount) {
    amount = action.amount * 100;
    chargeToken = action.data.chargeToken;
    if (action.bonusId) {
      bonusId = action.bonusId;
    }
  }

  try {
    let data = {
      chargeToken: chargeToken,
      // "redirectToACSForm": redirectToACSForm,
      amount: amount,
      bonusId: bonusId,
      holderName: action.holderName,
      events: getLogEvents(),
    };
    let pac = PlayerAbuseChecker.getInfo();
    data = {
      ...pac,
      ...data,
    };
    const response = yield axios({
      url: paths.paymentCheckout.SEND_VIVA_TOKEN,
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Send Viva Token response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Send Viva Token response has no 'result' property");
    }

    yield put(actions.receivedVivaChargeToken(response));
  } catch (error) {
    yield put(actions.receivedVivaChargeToken({ serverResponse: false }));

    console.log(error);
    return;
  }
}

export function* requestVivaChargeTokenSaga(action) {
  const axios = VivaAPI.getInstance();

  let amount = 0;
  let bonusGroupId = null;
  let playerData = action.playerData;
  let data = {
    amount: playerData.amount,
    cvc: playerData.cvc,
    number: parseInt(playerData.number.replace(/\s/g, '')),
    holderName: playerData.holderName,
    expirationYear: parseInt('20' + playerData.monthYear.substring(3, 5)),
    expirationMonth: parseInt(playerData.monthYear.substring(0, 2)),
  };

  if (action.playerData.amount) {
    amount = action.playerData.amount * 100;
  }

  if (action.playerData.bonusGroupId) {
    bonusGroupId = action.playerData.bonusGroupId;
  }

  try {
    yield put(actions.logEvent('request viva charge token: ' + amount));

    const response = yield axios({
      url: paths.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN,
      method: 'post',
      data: JSON.stringify(data),
    });

    if (!response) {
      throw new Error('[ERROR] Viva Charge Token response is empty!');
    }

    if (typeof response['data'] === 'undefined') {
      throw new Error("[ERROR] Viva Charge Token response has no 'data' property");
    }

    yield put(actions.sendVivaChargeToken(response.data, amount / 100, bonusGroupId));
  } catch (error) {
    console.log(error);
    yield put(actions.receivedVivaChargeToken({ serverResponse: false }));
  }
}
export function* requestVivaChargeTokenWithCardSaga(action) {
  const axios = VivaAPI.getInstance();
  const cardToken = action.cardToken;
  const playerData = action.playerData;
  const amount = playerData.amount * 100;
  const bonusId = action.playerData?.bonusId ?? null;

  try {
    yield put(actions.logEvent('request viva charge token with card: ' + amount));
    const response = yield axios({
      url: paths.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN,
      method: 'POST',
      data: {
        token: cardToken,
        amount: amount,
        sessionRedirectUrl:
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '') +
          '/deposit',
      },
    });

    if (!response) {
      throw new Error('[ERROR] Viva Charge Token With Card response is empty!');
    }

    if (typeof response['data'] === 'undefined') {
      throw new Error("[ERROR] Viva Charge Token With Card response has no 'chargeToken' property");
    }
    if (typeof response.data['redirectToACSForm'] !== 'undefined' && response.data.redirectToACSForm) {
      window.localStorage.setItem(
        'deposit',
        JSON.stringify({
          data: {
            chargeToken: response.data.chargeToken,
          },
          amount: amount,
          bonusId: bonusId,
          step: 'final-step',
          paymentProvider: PaymentProviderConstant.viva,
        }),
      );

      let wrapper = document.getElementById(HTML_3DS_IFRAME_ID);
      wrapper.className = 'show';
      let range = document.createRange();
      let documentFragment = range.createContextualFragment(response.data.redirectToACSForm);
      wrapper.appendChild(documentFragment);
      // window.jQuery('validation3ds').append(response.data.redirectToACSForm);
      // let form = document.getElementById("tdsMmethodForm");
      // if(form) {
      //     form.submit()
      // };
    } else {
      yield put(actions.sendVivaChargeToken(response.data, amount / 100, bonusId));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.receivedVivaChargeToken({ serverResponse: false }));
  }
}
