import React, { useContext } from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../store';
import craftJsParser from '../../components/utils/craftJsParser';
import { DataElementContext } from '../common/DataElementContext';
import { applyLinksValues } from '../utils/applyLinksValues';
import { evaluatePropFunction } from '../utils/evaluatePropFunction';
import { evaluateVisibilityFunction } from '../utils/evaluateVisibilityFunction';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';


const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    selectedLayout: '',
    layoutStates: [],
  },
  visibility: {},
};

const LayoutDiv = styled.div((props) => props.$styleText);

export const Layout = (componentProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  let props = JSON.parse(JSON.stringify(tmpProps));

  const dataElementContext = useContext(DataElementContext);
  const pageDataContext = useContext(PageDataContext);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user'),
  );

  let { selectedLayout, layoutStates } = props.properties;
  if (layoutStates == null || !Array.isArray(layoutStates) || layoutStates.length === 0) return null;

  if (!selectedLayout) {
    selectedLayout = layoutStates[0].value;
  }

  if (dataElementContext && props?.properties['links'] && Object.values(props?.properties['links']).length) {
    const tmp = { ...props?.properties };
    applyLinksValues(tmp, dataElementContext);
    if (layoutStates && Array.isArray(layoutStates)) {
      layoutStates.forEach((state) => {
        if (state.value === tmp.selectedLayout) {
          props.properties.selectedLayout = tmp.selectedLayout;
        }
      });
    }
  }

  if (props?.properties['propFunction'] != null && props?.properties['propFunction'])
    evaluatePropFunction(props, dataElementContext);

  if ((props?.visibility && props?.visibility['visibilityFunction'] != null) || props.hide != null || props.properties.__display != null) {
    const visible = evaluateVisibilityFunction(props, dataElementContext);
    if (visible != null && !visible) return null;
  }

  // we need to get the values again because the evaluation might have changed their value
  ({ selectedLayout, layoutStates } = props.properties);

  if (!selectedLayout)
    // the above evaluations might have invalidated this
    return null;

  let content = null;
  let hasError = false;

  try {
    const data = getProjectDataFromContext(pageDataContext);

    const layoutWrapperNodeId = props.linkedNodes[selectedLayout];

    if (!layoutWrapperNodeId) return null; // something is wrong; the referenced node to render this selected layout doesn't exists

    content = craftJsParser({
      craftState: data,
      rootNodeId: layoutWrapperNodeId,
      pageId: props.refType === 'layout' ? null : props.refId,
      pageLayoutId: props.refType === 'layout' ? props.refId : null,
      pageType: props.refType,
      options: {
        authenticated,
      },
    });
  } catch (err) {
    console.error(err);
    hasError = true;
  }

  if (hasError) return null;
  if (!content) return null;

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  return <LayoutDiv
    className={props.className ?? ''}
    $styleText={props.styleText}
    style={props.style}
    onClick={props.properties.onClick ?? null}
    {...extraDataProps}
  >
    {content}
  </LayoutDiv>;
};
