import Utils from '@/modules/casino/utils/Utils';
import { initiateBridgerApplePayDeposit } from '@/modules/casino/store/actions/deposit';

class BridgerApplePay {
  constructor(data: any) {
    this.init(data);
  }

  private amount: any;
  private bonusId: any;
  private triggerSuccess: any;
  private triggerError: any;
  private dispatch: any;
  private cashierKey: any;
  private cashierToken: any;

  startSession() {
    if (!this.isApplePayAvailable()) return;
    this.dispatch(
      initiateBridgerApplePayDeposit({
        amount: this.amount * 100,
        bonusId: this.bonusId,
      }),
    );
  }

  injectAppleScript(data: any): void {
    this.destroy();
    Utils.injectScript(
      'https://ng-checkout.bridgerpay.com/v2/launcher',
      'bridger-apple-pay',
      () => {
        const { cashierKey, cashierToken } = data;
        this.cashierKey = cashierKey;
        this.cashierToken = cashierToken;
        //listen for message
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-result`,
          (e: any) => {
            const result = e.detail;
            if (result.status === 'approved') {
              this.triggerSuccess();
            } else {
              this.triggerError();
            }
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-cancelled`,
          () => {
            this.triggerError();
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:no-wallet-provider-found`,
          () => {
            this.triggerError();
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-provider-cannot-be-used`,
          () => {
            this.triggerError();
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-shipping-contact-update`,
          (e: any) => {
            if (e.error || e.detail?.error) {
              this.triggerError();
            }
          },
          false,
        );
      },
      {
        data: {
          'cashier-key': data.cashierKey,
          'cashier-token': data.cashierToken,
          'button-mode': 'wallet',
        },
        container: 'apple-pay-placeholder',
      },
    );
  }

  destroy(): void {
    // remove apple pay script and apple pay button
    const applePayButton = document.getElementById('apple-pay-placeholder');
    if (applePayButton) {
      applePayButton.innerHTML = '';
    }
  }

  init(data: any): void {
    this.amount = data.amount;
    this.bonusId = data.bonusId;
    this.triggerSuccess = data.triggerSuccess;
    this.triggerError = data.triggerError;
    this.dispatch = data.dispatch;
    this.cashierKey = data.cashierKey;
    this.cashierToken = data.cashierToken;
  }

  setAmount(amount: number): void {
    this.amount = amount;
  }

  setBonusId(bonusId: number | null): void {
    this.bonusId = bonusId;
  }

  isApplePayAvailable() {
    //@ts-ignore
    if (window.ApplePaySession && window.config.bridgerApplePayEnabled === '1') {
      //@ts-ignore do we really need this check?
      if (window.ApplePaySession.canMakePayments()) {
        return true;
      }
    }
    return false;
  }

  onApplePayButtonClicked = () => {};

  // return (<div className="apple-pay-button apple-pay-button-white-with-line add-money" onClick={onApplePayButtonClicked}></div>);
}

export default BridgerApplePay;
