export const hardodedJackpotResp: any = {
  element_type: {
    type_id: 'module.jackpotV2Group',
    internal: [
      {
        id: 'key',
        type: 'string',
        value: null,
      },
      {
        id: 'value',
        type: 'string',
        value: null,
      },
      {
        id: 'pathParamKey',
        type: 'string',
        value: null,
      },
    ],
  },
  data: [
    {
      group: 'egt',
      provider: 'egt',
      jackpots: {
        element_type: {
          type_id: 'module.jackpotV2',
          internal: [],
        },
        data: [
          {
            id: 'I',
            name: 'I',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '9123456.05',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 12222,
            largest_win_date: 'Oct 28, 2023 1:00:27 AM',
            largest_win_player: 'player',
            last_win_amount: 133333,
            last_win_date: 'May 29, 2024 8:13:31 AM',
            last_win_player: 'player 1',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 11122,
                  date: 'May 24, 2024 9:37:32 AM',
                  player: 'player 12',
                },
                {
                  amount: 11333,
                  date: 'May 21, 2024 9:31:46 AM',
                  player: 'player 13',
                },
                {
                  amount: 11456,
                  date: 'May 4, 2024 12:25:17 PM',
                  player: 'player 14',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 11234,
                  date: 'Feb 23, 2024 12:37:13 PM',
                  player: 'player 1X',
                },
                {
                  amount: 123456,
                  date: 'Jan 1, 2024 5:24:29 PM',
                  player: 'player 1Y',
                },
                {
                  amount: 133456,
                  date: 'Jan 31, 2024 12:12:25 PM',
                  player: 'player 1YZ',
                },
              ],
            },
          },
          {
            id: 'II',
            name: 'II',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '2222.22',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 265259,
            largest_win_date: 'Apr 18, 2023 12:15:21 PM',
            largest_win_player: 'player 21',
            last_win_amount: 275793,
            last_win_date: 'May 29, 2024 6:38:23 AM',
            last_win_player: 'player 22',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 208445,
                  date: 'May 17, 2024 10:43:00 AM',
                  player: 'player 2ds',
                },
                {
                  amount: 293493,
                  date: 'May 2, 2024 6:26:41 PM',
                  player: 'player 22x',
                },
                {
                  amount: 278424,
                  date: 'May 14, 2024 5:59:54 PM',
                  player: 'player 2a',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 263217,
                  date: 'Apr 8, 2024 7:35:14 AM',
                  player: 'player 212',
                },
                {
                  amount: 239484,
                  date: 'Apr 20, 2024 6:29:20 AM',
                  player: 'player 222',
                },
                {
                  amount: 236495,
                  date: 'Feb 22, 2024 10:51:28 AM',
                  player: 'player 2sd',
                },
              ],
            },
          },
          {
            id: 'III',
            name: 'III',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '33333.33',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 36623824,
            largest_win_date: 'May 29, 2024 7:11:28 AM',
            largest_win_player: 'player',
            last_win_amount: 36623824,
            last_win_date: 'May 29, 2024 7:11:28 AM',
            last_win_player: 'player 31',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 36623824,
                  date: 'May 29, 2024 7:11:28 AM',
                  player: 'player 3a',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 36623824,
                  date: 'May 29, 2024 7:11:28 AM',
                  player: 'player 3gs',
                },
                {
                  amount: 19970077,
                  date: 'Jan 8, 2024 2:56:56 PM',
                  player: 'player 3sad',
                },
                {
                  amount: 19324140,
                  date: 'Feb 8, 2024 10:01:55 PM',
                  player: 'player 32sa',
                },
              ],
            },
          },
          {
            id: 'IV',
            name: 'IV',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '42345678.83',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 454145769,
            largest_win_date: 'Dec 4, 2023 10:24:05 AM',
            largest_win_player: 'player',
            last_win_amount: 405449933,
            last_win_date: 'Mar 4, 2024 1:07:25 PM',
            last_win_player: 'player 44asa',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 405449933,
                  date: 'Mar 4, 2024 1:07:24 PM',
                  player: 'player 4sa',
                },
              ],
            },
          },
        ],
      },
      art_bundle: {
        element_type: {
          type_id: 'arb_jackpot',
          internal: [],
        },
        data: {
          background_mobile: 'https://micros-t.b-cdn.net/jackpot-cards-logo-1702007329987.webp',
          background_tablet: 'https://micros-t.b-cdn.net/jp_header_desktop_jp1-1693581517885.jpg',
          background_desktop: 'https://micros-t.b-cdn.net/jp_header_desktop_jp1-1693581517885.jpg',
          card_background_mobile:
            'https://cgp-cdn.safe-iplay.com/assets/images/jackpot_widget/millionaire_genie/background.png',
          card_background_tablet:
            'https://cgp-cdn.safe-iplay.com/assets/images/jackpot_widget/millionaire_genie/background.png',
          card_background_desktop:
            'https://cgp-cdn.safe-iplay.com/assets/images/jackpot_widget/millionaire_genie/background.png',
          text_group_display_name: 'Jackpot HardCoded',
          text_jackpot_name_0: 'Spades',
          text_jackpot_name_1: 'Hearts',
          text_jackpot_name_2: 'Diamonds',
          text_jackpot_name_3: 'Clubs',
          jackpot_icon_0: '',
          jackpot_icon_1: '',
          jackpot_icon_2: '',
          jackpot_icon_3: '',
        },
      },
    },
    {
      group: 'egt_egypt_quest',
      provider: 'egt',
      jackpots: {
        element_type: {
          type_id: 'module.jackpotV2',
          internal: [],
        },
        data: [
          {
            id: 'I',
            name: 'I',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '11.11',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 1111,
            largest_win_date: 'Nov 28, 2023 2:30:07 AM',
            largest_win_player: 'player 4asd',
            last_win_amount: 1112,
            last_win_date: 'May 29, 2024 7:34:37 AM',
            last_win_player: 'player 111',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 18668,
                  date: 'May 21, 2024 3:11:35 PM',
                  player: 'player 1sd',
                },
                {
                  amount: 15912,
                  date: 'May 3, 2024 5:25:59 PM',
                  player: 'player 1sd',
                },
                {
                  amount: 15738,
                  date: 'May 15, 2024 12:36:14 AM',
                  player: 'player 1sds',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 18703,
                  date: 'Feb 1, 2024 10:38:33 PM',
                  player: 'player 1ds',
                },
                {
                  amount: 128667,
                  date: 'Jan 20, 2024 3:17:59 PM',
                  player: 'player 1sd',
                },
                {
                  amount: 127949,
                  date: 'Jan 23, 2024 10:58:42 AM',
                  player: 'player 1fhg',
                },
              ],
            },
          },
          {
            id: 'II',
            name: 'II',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '222222.22',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 281048,
            largest_win_date: 'Nov 28, 2023 2:57:09 AM',
            largest_win_player: 'player 2hgh',
            last_win_amount: 20209,
            last_win_date: 'May 29, 2024 7:19:09 AM',
            last_win_player: 'player 2xhd',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 240147,
                  date: 'May 25, 2024 4:04:51 PM',
                  player: 'player 2fds',
                },
                {
                  amount: 233549,
                  date: 'May 21, 2024 3:37:18 PM',
                  player: 'player 2hf',
                },
                {
                  amount: 233323,
                  date: 'May 26, 2024 9:21:29 PM',
                  player: 'player 2jhg',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 250509,
                  date: 'Jan 21, 2024 9:52:55 AM',
                  player: 'player 2g',
                },
                {
                  amount: 249628,
                  date: 'Jan 23, 2024 6:49:30 AM',
                  player: 'player 2t32',
                },
                {
                  amount: 248615,
                  date: 'Apr 19, 2024 8:48:24 PM',
                  player: 'player 2dfd',
                },
              ],
            },
          },
          {
            id: 'III',
            name: 'III',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '3333333.33',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 353246,
            largest_win_date: 'Nov 3, 2023 11:15:16 PM',
            largest_win_player: 'player 3dfs',
            last_win_amount: 333523,
            last_win_date: 'May 29, 2024 3:32:30 AM',
            last_win_player: 'player 3gfd',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 385331,
                  date: 'May 25, 2024 3:40:32 AM',
                  player: 'player 3hfd',
                },
                {
                  amount: 378828,
                  date: 'May 20, 2024 7:29:38 AM',
                  player: 'player 3tf',
                },
                {
                  amount: 374319,
                  date: 'May 23, 2024 12:26:42 PM',
                  player: 'player 3ds',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 397110,
                  date: 'Mar 2, 2024 11:46:55 PM',
                  player: 'player 3s',
                },
                {
                  amount: 395408,
                  date: 'Feb 22, 2024 4:51:30 PM',
                  player: 'player 3gf',
                },
                {
                  amount: 3192616,
                  date: 'Mar 12, 2024 9:27:14 PM',
                  player: 'player 3hgf',
                },
              ],
            },
          },
          {
            id: 'IV',
            name: 'IV',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '42345678.99',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 48756638,
            largest_win_date: 'Jun 19, 2023 6:27:15 AM',
            largest_win_player: 'player 4s',
            last_win_amount: 4739275,
            last_win_date: 'Mar 8, 2024 10:23:23 PM',
            last_win_player: 'player 4g',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 46787462,
                  date: 'Feb 10, 2024 11:35:58 PM',
                  player: 'player 4hf',
                },
                {
                  amount: 44739275,
                  date: 'Mar 8, 2024 10:23:23 PM',
                  player: 'player 4hgf',
                },
              ],
            },
          },
        ],
      },
      art_bundle: {
        element_type: {
          type_id: 'arb_jackpot',
          internal: [],
        },
        data: {
          background_mobile: 'https://micros-t.b-cdn.net/egypt-quest-logo-1702007335667.webp',
          background_tablet: 'https://micros-t.b-cdn.net/jackpot-header-7-1692012443901.png',
          background_desktop: 'https://micros-t.b-cdn.net/jackpot-header-7-1692012443901.png',
          card_background_mobile: 'https://cgp-cdn.safe-iplay.com/assets/images/jackpot_widget/daily/background.png',
          card_background_tablet: 'https://cgp-cdn.safe-iplay.com/assets/images/jackpot_widget/daily/background.png',
          card_background_desktop: 'https://cgp-cdn.safe-iplay.com/assets/images/jackpot_widget/daily/background.png',
          text_group_display_name: 'Jackpot Egypt Quest',
          text_jackpot_name_0: 'Platinum',
          text_jackpot_name_1: 'Gold',
          text_jackpot_name_2: 'Silver',
          text_jackpot_name_3: 'Bronze',
          jackpot_icon_0: '',
          jackpot_icon_1: '',
          jackpot_icon_2: '',
          jackpot_icon_3: '',
        },
      },
    },
  ],
};
